import React, { useRef, useEffect, useState } from "react";
import {
  Card,
  Button,
  CardContent,
  CardActions,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { languages } from "../../../constants/languages/languages";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage].addBackgroundImages;
  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState();
  const [imageData, setImageData] = useState();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [multipleBackgroundImageState, setMultipleBackgroundImageState] =
    useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const options = users.map((user) => ({
    value: user.userId,
    label: `${user.userName} ${user.userSurname} - ${user.userFirmName}`,
  }));

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      myToast({ status: "error", message: texts.selectBackgroundImageError });
      return;
    }

    setImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageData = reader.result;
      setImageData(imageData);
    };
  };

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}:${API_PORT}/user/getAllUser`);
      const data = await response.json();
      setUsers(data);
      setSelectedUser(data[0].userId);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image);
      const imageType = "backgroundimages";
      const response = await fetch(
        `${API_URL}:${API_PORT}/image/uploadImage?imageType=${imageType}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  const SaveBackgroundImageAndRelationUser = async () => {
    setLoading(true);
    try {
      const imageResponse = await uploadImage();
      if (imageResponse && imageResponse.imageId) {
        const ENDPOINT = "ImageUserRelation/saveImageUserRelation";
        const userId = selectedUser.value;
        const imageId = imageResponse.imageId;
        const relation = {
          image: { imageId },
          user: { userId },
          imageType: "backgroundimages",
          status: true,
          singleOrMultiple: multipleBackgroundImageState,
        };

        const response = await fetch(`${API_URL}:${API_PORT}/${ENDPOINT}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(relation),
        });

        if (response.status === 200) {
          myToast({
            status: "success",
            message: texts.backgroundImagesSaveSuccess,
          });
          navigate("/background-images");
        } else {
          myToast({ status: "error", message: texts.backgroundImageSaveError });
        }
      } else {
        myToast({ status: "error", message: texts.backgroundImageSaveError });
        return;
      }
    } catch (error) {
      myToast({ status: "error", message: texts.backgroundImageSaveError });
    }
    setLoading(false);
  };

  const handleOpen = () => setOpenModal(!openModal);

  return (
    <>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          boxShadow: 3,
          m: 4,
          py: 4,
        }}
      >
        {texts.addCategory}
        <div className="relative w-full flex justify-center">
          <img
            className="h-[438px] w-[310px] rounded-xl object-cover object-center shadow-xl"
            src={imageData || "https://placehold.co/2480x3508"}
            alt="categoryImage"
          />
          <input
            type="file"
            id="file-upload"
            accept="image/jpeg, image/png, image/jpg"
            ref={fileInputRef}
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <label
            htmlFor="file-upload"
            className="absolute flex items-center justify-center cursor-pointer"
            onClick={triggerFileInput}
          >
            <Button
              className="w-[310px] rounded-none rounded-t-xl opacity-75 font-[Inter]"
              variant="contained"
              color="primary"
            >
              {texts.addImage}
            </Button>
          </label>
        </div>
        <CardContent>
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="w-full h-full flex flex-col gap-2">
              <Select
                className="w-full h-10 rounded-md px-2"
                value={selectedUser}
                onChange={handleUserChange}
                options={options}
                placeholder={texts.selectUserError}
                isClearable
              />
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={!multipleBackgroundImageState}
                      onChange={() => setMultipleBackgroundImageState(false)}
                    />
                  }
                  label={texts.multipleBackgroundImages}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={multipleBackgroundImageState}
                      onChange={() => setMultipleBackgroundImageState(true)}
                    />
                  }
                  label={texts.singleBackgroundImage}
                />
              </div>
            </div>
          )}
        </CardContent>
        <CardActions className="flex justify-end">
          <Button
            color="secondary"
            onClick={() => navigate(-1)}
            className="mr-1 font-[Inter]"
          >
            <span>{texts.backgroundImageCancel}</span>
          </Button>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={SaveBackgroundImageAndRelationUser}
          >
            <span>{texts.backgroundImageSave}</span>
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default Index;
