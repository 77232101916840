import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  CardActions,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  IconButton,
} from "@mui/material";
import { languages } from "../../constants/languages/languages";
import React, { useEffect, useState } from "react";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../components/languageProvider";
import myToast from "../../components/myToast";
import AddPlusCircleIcon from "@mui/icons-material/AddCircle";
import MagnifyingGlassIcon from "@mui/icons-material/Search";
import TrashIcon from "@mui/icons-material/Delete";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const size = 10;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userDto, setUserDto] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);

  const getAllBackgroundImagesByUserId = async (userId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageUserRelation/findImageByUserIdAndImageType?userId=${userId}&imageType=backgroundimages`
      );
      const data = await response.json();
      return data?.content?.length > 0 ? data.content : [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getAllUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/user/getAllUser?page=${page}&size=${size}`
      );
      const data = await response.json();

      const usersWithImages = await Promise.all(
        data.map(async (user) => {
          const images = await getAllBackgroundImagesByUserId(user.userId);
          return images.length > 0 ? { ...user, images } : null;
        })
      );

      setUserDto(usersWithImages.filter((user) => user !== null));
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllUser();
  }, [page]);

  const TABLE_HEAD = [
    texts.userTableTitle.userName,
    texts.userTableTitle.userBackgrondImages,
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleOpenDeleteDialog = (image) => {
    setDeleteDialog(true);
    setSelectedBackgroundImage(image?.image);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const handelDeleteBackgroundImage = async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageUserRelation/deleteUserBackgroundImages?imageId=${selectedBackgroundImage?.imageId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        myToast({
          status: "success",
          message: texts.addBackgroundImages.deleteBackgroundImageSuccess,
        });
        getAllUser();
      } else {
        myToast({
          status: "error",
          message: texts.addBackgroundImages.deleteBackgroundImageError,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteLoading(false);
    setDeleteDialog(false);
  };

  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4">
        <CardHeader title={texts.userBackgrondImages} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddPlusCircleIcon />}
                onClick={() => navigate("/background-images/add")}
              >
                {texts.addBackgroundImages.addBackgroundImage}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={texts.search}
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MagnifyingGlassIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer className="mt-4">
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((head) => (
                      <TableCell key={head} className="bg-gray-200">
                        <Typography variant="body1">{head}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userDto.map((item, index) => {
                    const isLast = index === userDto.length - 1;
                    return (
                      <TableRow key={item.userId}>
                        <TableCell
                          className={isLast ? "font-bold" : "border-b"}
                        >
                          <Typography>
                            {item?.userName} {item?.userSurname}
                            <br />
                            {item?.userFirmName}
                          </Typography>
                        </TableCell>
                        <TableCell
                          className={isLast ? "font-bold" : "border-b"}
                          sx={{ whiteSpace: "nowrap", padding: 0 }} // Resimlerin dışarı taşmasını engelleme
                        >
                          <div
                            className="flex gap-4 overflow-x-auto" // Yatay kaydırma için flex yapısı
                            style={{
                              padding: "10px 0", // Resimlere biraz boşluk ekleme
                            }}
                          >
                            {item?.images?.map((image, index) => (
                              <div
                                key={index}
                                style={{ position: "relative", flexShrink: 0 }} // Flex ile esnek olmayan öğe yapıldı
                              >
                                <img
                                  style={{
                                    height: "105px",
                                    width: "75px",
                                    objectFit: "cover",
                                    borderRadius: "1px",
                                    display: "block",
                                  }}
                                  src={`${API_URL}:${API_PORT}/${image?.image?.imageName}`}
                                  alt={`Image_${index + 1}`}
                                />
                                <IconButton
                                  onClick={() => handleOpenDeleteDialog(image)}
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    backgroundColor: "transparent",
                                    "&:hover": {
                                      backgroundColor:
                                        "rgba(240, 240, 240, 0.9)", // Hover rengi
                                    },
                                  }}
                                >
                                  <TrashIcon
                                    sx={{
                                      color: "red",
                                      width: "24px",
                                      height: "24px",
                                    }}
                                  />
                                </IconButton>
                              </div>
                            ))}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handlePageChange}
          />
        </CardActions>
      </Card>

      <Dialog open={deleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>
          {texts.addBackgroundImages.deleteImageWarning}
        </DialogTitle>
        <DialogContent>
          {selectedBackgroundImage && (
            <img
              src={`${API_URL}:${API_PORT}/${selectedBackgroundImage?.imageName}`}
              alt="Selected Image"
              style={{ width: "100%", maxWidth: "400px" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            {texts.no}
          </Button>
          <Button
            onClick={handelDeleteBackgroundImage}
            color="secondary"
            disabled={deleteLoading}
          >
            {deleteLoading ? <CircularProgress size={24} /> : texts.yes}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Index;
