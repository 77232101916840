import {
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { languages } from "../../../constants/languages/languages";
import React, { useEffect, useState } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate, useParams } from "react-router-dom";

const Index = () => {
  const { id } = useParams();
  const { currentLanguage } = useLanguage();
  const navigate = useNavigate();
  const texts = languages[currentLanguage];
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState({
    printNameTr: "",
    printNameEn: "",
    printNameDe: "",
    printNameFr: "",
    printNameIt: "",
    printNameNl: "",
    printDescription: "",
    printPrice: "",
  });

  const savePrice = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}:${API_PORT}/print/savePrint`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(print),
      });
      if (response?.status === 200) {
        myToast({
          status: "success",
          message: texts.successMessages.saveSuccess,
        });
        navigate("/print/review-prices");
      } else {
        myToast({
          status: "error",
          message: texts.errorMessages.saveError,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getPrintPrice = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/print/findByLikePrintId?printId=${id}`
      );
      const data = await response.json();
      setPrint(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      getPrintPrice();
    }
  }, [id]);

  return (
    <Card sx={{ width: "100%", height: "100%", boxShadow: 3, p: 4 }}>
      <Typography variant="h5" gutterBottom>
        {texts.printPriceTableTitle.addPrice}
      </Typography>

      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <TextField
              label={texts.addPrintTable.printNameDe}
              value={print.printNameDe}
              onChange={(e) =>
                setPrint({
                  ...print,
                  printNameDe: e.target.value,
                })
              }
              fullWidth
              variant="outlined"
              size="small" // Küçük boyut
            />
            <TextField
              label={texts.addPrintTable.printNameEn}
              value={print.printNameEn}
              onChange={(e) =>
                setPrint({
                  ...print,
                  printNameEn: e.target.value,
                })
              }
              fullWidth
              variant="outlined"
              size="small" // Küçük boyut
            />
            <TextField
              label={texts.addPrintTable.printNameTr}
              value={print.printNameTr}
              onChange={(e) =>
                setPrint({
                  ...print,
                  printNameTr: e.target.value,
                })
              }
              fullWidth
              variant="outlined"
              size="small" // Küçük boyut
            />
            <TextField
              label={texts.addPrintTable.printNameFr}
              value={print.printNameFr}
              onChange={(e) =>
                setPrint({
                  ...print,
                  printNameFr: e.target.value,
                })
              }
              fullWidth
              variant="outlined"
              size="small" // Küçük boyut
            />
            <TextField
              label={texts.addPrintTable.printNameIt}
              value={print.printNameIt}
              onChange={(e) =>
                setPrint({
                  ...print,
                  printNameIt: e.target.value,
                })
              }
              fullWidth
              variant="outlined"
              size="small" // Küçük boyut
            />
            <TextField
              label={texts.addPrintTable.printNameNl}
              value={print.printNameNl}
              onChange={(e) =>
                setPrint({
                  ...print,
                  printNameNl: e.target.value,
                })
              }
              fullWidth
              variant="outlined"
              size="small" // Küçük boyut
            />
            <TextField
              label={texts.addPrintTable.printDescription}
              value={print.printDescription}
              onChange={(e) =>
                setPrint({
                  ...print,
                  printDescription: e.target.value,
                })
              }
              fullWidth
              variant="outlined"
              size="small" // Küçük boyut
            />
            <TextField
              type="number"
              label={texts.addPrintTable.printPrice}
              value={print.printPrice}
              onChange={(e) =>
                setPrint({
                  ...print,
                  printPrice: e.target.value,
                })
              }
              fullWidth
              variant="outlined"
              size="small" // Küçük boyut
            />
          </div>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="error"
          onClick={() => navigate("/print/review-prices")}
          className="mr-1"
        >
          <span>{texts.addPrintTable.printCancel}</span>
        </Button>
        <Button onClick={() => savePrice()} color="success">
          <span>{texts.addPrintTable.printSave}</span>
        </Button>
      </CardActions>
    </Card>
  );
};

export default Index;
