import React, { createContext, useState, useContext, useEffect } from "react";

const UserRoleContext = createContext();

export const UserRoleProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(
    localStorage.getItem("userRole") || "guest"
  );

  useEffect(() => {
    localStorage.setItem("userRole", userRole);
  }, [userRole]);

  const changeUserRole = (role) => {
    setUserRole(role);
  };

  return (
    <UserRoleContext.Provider value={{ userRole, changeUserRole }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => useContext(UserRoleContext);
