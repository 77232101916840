import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
} from "@mui/material";
import { languages } from "../../../constants/languages/languages";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { useEffect, useState } from "react";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PencilIcon from "@mui/icons-material/Edit";

import myToast from "../../../components/myToast";

const PriceName = ({ item, currentLanguage }) => {
  const printNames = {
    tr: item.printNameTr,
    de: item.printNameDe,
    fr: item.printNameFr,
    it: item.printNameIt,
    nl: item.printNameNl,
    en: item.printNameEn,
  };

  return (
    <Typography variant="body1" fontWeight="bold">
      {printNames[currentLanguage] || printNames.en}
    </Typography>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [printPrices, setPrintPrices] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getAllPrintRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/print/getAllPrint?page=${page}&size=${size}&sort=printPrice,asc`
      );
      const data = await response.json();
      setPrintPrices(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPrintRequest();
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleDeletePrintPrice = async (selectedPrintPrice) => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/print/deletePrint?printId=${selectedPrintPrice.printId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        getAllPrintRequest();
        myToast({
          status: "success",
          message: texts.successMessages.deleteSuccess,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteLoading(false);
  };

  return (
    <Card sx={{ width: "100%", height: "100%", boxShadow: 3, mx: 4 }}>
      <div className="flex justify-between p-4">
        <Typography variant="h5" className="px-4">
          {texts.printerPage}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/print/add-price")}
          size="small"
        >
          {texts.printPriceTableTitle.addPrice}
        </Button>
      </div>
      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    texts.printPriceTableTitle.printName,
                    texts.printPriceTableTitle.printDescription,
                    texts.printPriceTableTitle.printPrice,
                    "",
                  ].map((head) => (
                    <TableCell key={head} align="left">
                      <Typography variant="body2" fontWeight="bold">
                        {head}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {printPrices.map((item, index) => (
                  <TableRow key={item.printId}>
                    <TableCell>
                      <PriceName
                        item={item}
                        currentLanguage={currentLanguage}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography>{item?.printDescription}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item?.printPrice} €</Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={texts.printPriceTableTitle.editPrice}>
                        <IconButton
                          onClick={() =>
                            navigate(`/print/add-price/${item.printId}`)
                          }
                          disabled={deleteLoading}
                        >
                          <PencilIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={texts.printPriceTableTitle.deletePrice}>
                        <IconButton
                          onClick={() => handleDeletePrintPrice(item)}
                          disabled={deleteLoading}
                        >
                          <DeleteForeverIcon sx={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={handlePageChange}
        />
      </CardActions>
    </Card>
  );
};

export default Index;
