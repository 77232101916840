const countries = [
  {
    id: "tr",
    name: "Türkçe", // Türkçe dilinde
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/tr.svg",
    },
  },
  {
    id: "en",
    name: "English", // İngilizce dilinde
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/gb.svg",
    },
  },
  {
    id: "de",
    name: "Deutsch", // Almanca dilinde
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/de.svg",
    },
  },
  {
    id: "fr",
    name: "Français", // Fransızca dilinde
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/fr.svg",
    },
  },
  {
    id: "it",
    name: "Italiano", // İtalyanca dilinde
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/it.svg",
    },
  },
  {
    id: "nl",
    name: "Nederlands", // Hollandaca dilinde
    flags: {
      svg: "https://flagicons.lipis.dev/flags/4x3/nl.svg",
    },
  },
];

const countryOptions = countries.map(({ name, flags, id }) => ({
  value: id,
  label: (
    <div className="flex items-center gap-2">
      <img
        src={flags.svg}
        alt={name}
        className="h-5 w-5 rounded-full object-cover"
      />
      {name}
    </div>
  ),
}));

export { countries, countryOptions };
