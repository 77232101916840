import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useLanguage } from "../../components/languageProvider";
import EmptyImage from "../../assets/images/emptyProduct.svg";
import myToast from "../../../src/components/myToast";
import { languages } from "../../constants/languages/languages";

const SubCategoryName = ({ item, currentLanguage }) => {
  const subCategoryName = {
    tr: item.subCategoryNameTr,
    de: item.subCategoryNameDe,
    fr: item.subCategoryNameFr,
    it: item.subCategoryNameIt,
    nl: item.subCategoryNameNl,
    en: item.subCategoryNameEn,
  };

  return (
    <strong>{subCategoryName[currentLanguage] || subCategoryName.en}</strong>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const [products, setProducts] = useState([]);
  const size = 6;
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [selectedProductSubCategories, setSelectedProductSubCategories] =
    useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [deleteProductModal, setDeleteProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getAllProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ProductSubCategoryRelation/getAllProductSubCategoryRelation?page=${page}&size=${size}&sort=ps.product.createdAt,desc`
      );
      const data = await response.json();

      setProducts(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllProducts();
  }, [page]);

  const handleShowSubCategories = (item) => {
    setShowSubCategories(true);
    setSelectedProductSubCategories(item?.subCategories);
  };
  const handleHideSubCategories = () => {
    setShowSubCategories(false);
  };

  const handleShowImageDialog = (imagePath) => {
    setSelectedImage(`${API_URL}:${API_PORT}/${imagePath}`);
    setShowImageDialog(true);
  };

  const handleHideImageDialog = () => {
    setShowImageDialog(false);
  };

  const handleShowDeleteDialog = (item) => {
    setDeleteProductModal(true);
    setSelectedProduct(item);
  };
  const handleHideDeleteDialog = () => {
    setDeleteProductModal(false);
  };

  const handleDeleteProduct = (item) => async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ProductSubCategoryRelation/deleteProduct?productId=${item?.product?.productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        myToast({ status: "success", message: texts.productDeleteSuccess });
        setDeleteProductModal(false);
        getAllProducts();
      } else {
        myToast({ status: "error", message: texts.productDeleteError });
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteLoading(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value - 1);
  };

  const TABLE_HEAD = [
    texts.productsTableTitle.productImage,
    texts.productsTableTitle.productUser,
    texts.productsTableTitle.category,
    "",
  ];

  return (
    <>
      <Card className="w-full h-full shadow-2xl mx-4">
        <CardContent>
          <span className="text-xl">{texts.products}</span>
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer
              sx={{
                maxHeight: "calc(100vh - 200px)",
                maxWidth: "100%",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((head) => (
                      <TableCell key={head}>{head}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((item, index) => (
                    <TableRow key={item.productId}>
                      <TableCell>
                        <img
                          src={
                            item?.product?.productImagePath
                              ? `${API_URL}:${API_PORT}/${item?.product?.productImagePath}`
                              : EmptyImage
                          }
                          alt={item?.product?.productName}
                          style={{ height: 105, width: 70, objectFit: "cover" }}
                        />
                      </TableCell>
                      <TableCell>
                        {item?.product?.user?.userName +
                          " " +
                          item?.product?.user?.userSurname}
                        <br />
                        {item?.product?.user?.userFirmName}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleShowSubCategories(item)}
                          sx={{
                            backgroundColor: "#FFA726", // Dikkat çekici turuncu ton
                            color: "white",
                            borderRadius: "8px", // Daha yumuşak köşeler
                            textTransform: "none", // Yazı formatını normal tut
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Hafif gölge efekti
                            padding: "6px 16px", // Butonun içinde daha ferah alan
                            transition: "all 0.3s ease", // Hover geçişi için animasyon
                            "&:hover": {
                              backgroundColor: "#FB8C00", // Hover'da daha koyu turuncu
                              boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)", // Hover'da gölgeyi artır
                            },
                            "&:active": {
                              backgroundColor: "#EF6C00", // Tıklama anında daha koyu turuncu
                            },
                          }}
                        >
                          {texts.showCategories}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={texts.deleteProduct}>
                          <IconButton
                            color="error"
                            onClick={() => handleShowDeleteDialog(item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={texts.showProductImage}>
                          <IconButton
                            onClick={() =>
                              item?.product?.productImagePath
                                ? handleShowImageDialog(
                                    item?.product?.productImagePath
                                  )
                                : myToast({
                                    status: "error",
                                    message: texts.noImageFound,
                                  })
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "center", py: 4 }}>
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handlePageChange}
          />
        </CardActions>
      </Card>

      <Dialog open={showSubCategories} onClose={handleHideSubCategories}>
        <DialogTitle>{texts.categories}</DialogTitle>
        <DialogContent>
          <Table>
            <TableBody>
              {selectedProductSubCategories?.map((item) => (
                <TableRow key={item.productSubCategoryId}>
                  <TableCell>
                    <SubCategoryName
                      item={item}
                      currentLanguage={currentLanguage}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHideSubCategories} color="error">
            {texts.close}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showImageDialog}
        onClose={handleHideImageDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogContent
          sx={{
            padding: 0, // Gereksiz boşlukları kaldırıyoruz
            position: "relative", // İkonu doğru şekilde yerleştirmek için
            display: "flex", // Resmi ve içeriği ortalamak için
            justifyContent: "center", // Yatayda ortalamak için
            alignItems: "center", // Dikeyde ortalamak için
            height: "100vh", // Diyalog kutusunun yüksekliğini tam ekran yapıyoruz
            overflow: "hidden", // Taşan içerikleri gizlemek için
          }}
        >
          {selectedImage && (
            <>
              <IconButton
                style={{
                  position: "absolute",
                  right: 16,
                  top: 16,
                  zIndex: 10, // İkonun diğer içeriklerin üstünde olmasını sağlıyoruz
                  color: "white", // İkonun rengini belirginleştiriyoruz
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Arka planı yarı saydam yaparak görünürlüğü artırıyoruz
                  padding: "8px", // İkonun tıklanabilir alanını artırıyoruz
                }}
                onClick={handleHideImageDialog}
              >
                <CloseIcon />
              </IconButton>
              <img
                src={selectedImage}
                alt="Selected"
                style={{
                  maxWidth: "100%", // Enini kutuya sığdır
                  maxHeight: "100%", // Boyunu kutuya sığdır
                  objectFit: "contain", // Orantıyı koruyarak kutuya sığdır
                  display: "block", // Alt boşluğu kaldırmak için
                  margin: "auto", // Görüntüyü ortalamak için
                }}
              />
            </>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={deleteProductModal} onClose={handleHideDeleteDialog}>
        <DialogTitle>{texts.productDeleteInfo}</DialogTitle>
        <DialogActions>
          <Button onClick={handleHideDeleteDialog} color="primary">
            {texts.productCancel}
          </Button>
          <Button
            onClick={handleDeleteProduct(selectedProduct)}
            color="error"
            disabled={deleteLoading}
          >
            {deleteLoading ? (
              <CircularProgress size={24} />
            ) : (
              texts.productDelete
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Index;
