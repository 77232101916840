import React, { useState } from "react";
import Logo from "../../assets/images/backgroundLogo.svg";
import myToast from "../../components/myToast";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../components/languageProvider";
import { useUserRole } from "../../components/UserRoleProvider";
import { languages } from "../../constants/languages/languages";
import Select from "react-select";
import Box from "@mui/material/Box";
import { countryOptions } from "../../constants/countries";

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
};

const Login = ({ setUserLoggedIn }) => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const { changeUserRole } = useUserRole();
  const texts = languages[currentLanguage].loginPage;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // useNavigate kancasını kullanıyoruz.

  const handleLogin = () => {
    try {
      if (username === "afly" && password === "Aykut-Mert2003") {
        myToast({ status: "success", message: texts.loginSuccess });
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("userRole", "superadmin");
        changeUserRole("superadmin"); // UserRoleProvider'ı güncelle
        setUserLoggedIn(true);
        navigate("/dashboard");
      } else {
        myToast({
          status: "error",
          message: texts.loginError,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-full h-screen bg-[#FBF8F4] flex flex-row justify-center items-center">
      <div className="bg-white font-[Inter] p-8 rounded-lg shadow-xl flex flex-col items-center">
        <img src={Logo} alt="Logo" className="w-20 h-20 mb-4" />
        <h2 className="text-2xl font-semibold mb-4">Giriş Yap</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <Box
            sx={{
              padding: 2,
              textAlign: "center",
              borderTop: "1px solid #ddd",
            }}
          >
            <Select
              options={countryOptions}
              styles={customStyles}
              value={countryOptions.find(
                (country) => country.value === currentLanguage
              )} // Seçilen ülkenin option objesini bul
              onChange={(e) => changeLanguage(e.value)} // Seçilen ülkenin id'sini kaydet
              isSearchable
              className="font-[Inter] w-full"
            />
          </Box>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-700 font-semibold"
            >
              {texts.username}
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-3 py-2 border rounded-md mt-1"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 font-semibold"
            >
              {texts.password}
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-3 py-2 border rounded-md mt-1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 w-full text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            {texts.login}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
