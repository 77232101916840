import CreateIcon from "@mui/icons-material/Create";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Card,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import myToast from "../../components/myToast";
import { useLanguage } from "../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import { QueryStats, DeleteOutline as TrashIcon } from "@mui/icons-material";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage].dealerTexts;
  const [dealers, setDealers] = useState([]);
  const size = 10;
  const [page, setPage] = useState(0); // 1'den başlatıyoruz
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDealerModal, setDeleteDealerModal] = useState(false);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  const getAllDealer = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/dealerPostalCode/getAllDealerPostalCode?sort=createdAt,desc&page=${
          page - 1
        }&size=${size}` // MUI Pagination 1 tabanlı olduğu için -1 yapıyoruz
      );
      const data = await response.json();
      console.log("Bayiler:", data?.content);
      const dealers = data?.content || [];
      setTotalPages(data?.totalPages);
      setDealers(dealers);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllDealer();
  }, [page]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1); // MUI Pagination 1 tabanlı olduğu için -1 yapıyoruz
  };

  const handleOpenDeleteDealerModal = (dealer) => {
    setSelectedDealer(dealer);
    setDeleteDealerModal(true);
  };

  const handleDeleteDealer = async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/dealer/deleteDealer?dealerId=${selectedDealer.dealerId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        myToast({
          status: "success",
          message: texts.deleteDealerSuccess,
        });
        getAllDealer();
      } else {
        myToast({
          status: "error",
          message: texts.deleteDealerError,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteDealerModal(false);
    setDeleteLoading(false);
  };

  return (
    <>
      <div className="w-full h-full  mx-4">
        <span className="px-4 py-2 text-xl">{texts.users}</span>

        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            gap: "16px",
          }}
        >
          <Button
            className="w-60 mb-4"
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => {
              navigate("/dealer/add-dealer");
            }}
          >
            {texts.addDealer}
          </Button>

          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <CircularProgress size={80} />
            </div>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>{texts.dealerTableTitle.dealerName}</TableCell>
                    <TableCell>{texts.dealerTableTitle.dealerPhone}</TableCell>
                    <TableCell>
                      {texts.dealerTableTitle.dealerAddress}
                    </TableCell>
                    <TableCell>{texts.dealerPostalCode}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealers.map((item) => (
                    <TableRow key={item.dealerId}>
                      <TableCell>
                        <Avatar
                          src="https://www.svgrepo.com/show/527630/buildings-3.svg"
                          size="small"
                        />
                      </TableCell>
                      <TableCell>{item.dealerName}</TableCell>
                      <TableCell>{item.dealerPhone}</TableCell>
                      <TableCell>{item.dealerAddress}</TableCell>
                      <TableCell>
                        {item.postalCodes.map((postalCode, index) => (
                          <div key={index}>{postalCode.postalCode}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        <Tooltip title={texts.viewDealerStatistics}>
                          <IconButton
                            onClick={() => {
                              navigate(
                                `/dealer/dealer-statistics/${item.dealerId}`
                              );
                            }}
                          >
                            <QueryStats fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={texts.editDealer}>
                          <IconButton
                            onClick={() => {
                              navigate(`/dealer/add-dealer/${item.dealerId}`);
                            }}
                          >
                            <CreateIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={texts.deleteDealer}>
                          <IconButton
                            onClick={() => {
                              handleOpenDeleteDealerModal(item);
                            }}
                          >
                            <TrashIcon fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "center", py: 4 }}>
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handlePageChange}
          />
        </CardActions>
      </div>
      <Dialog
        open={deleteDealerModal}
        onClose={() => setDeleteDealerModal(false)}
      >
        <DialogTitle>{texts.deleteDealerMessage}</DialogTitle>
        <DialogContent>
          <span>{texts.deleteDealerMessage}</span>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDeleteDealerModal(false)}
          >
            {texts.no}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteDealer}
            disabled={deleteLoading}
            startIcon={deleteLoading && <CircularProgress size={20} />}
          >
            {texts.yes}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Index;
