import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import { useLanguage } from "../../components/languageProvider";
import { toast } from "react-toastify";
import moment from "moment";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const [payments, setPayments] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const getAllPayments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/payment/getAllPayment?page=${page-1}&size=${size}&sort=createdAt,desc`
      );
      const data = await response.json();
      console.log(data);
      if (!data) {
        toast.error(texts.paymentTableTitle.paymentNotFound);
        setPage(0);
      }
      setPayments(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUpdatePaymentStatus = async (payment, status) => {
    try {
      const updatePayment = {
        paymentId: payment.paymentId,
        status: status === "true" ? true : false,
        user: {
          userId: payment.user.userId,
        },
        paymentAmount: payment.paymentAmount,
        paymentDate: payment.paymentDate,
        paymentDescription: payment.paymentDescription,
      };

      const response = await fetch(
        `${API_URL}:${API_PORT}/payment/savePayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatePayment),
        }
      );
      const data = await response.json();
      if (data) {
        toast.success(texts.paymentTableTitle.paymentStatusUpdated);
        getAllPayments();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPayments();
  }, [page]);

  const TABLE_HEAD = [
    texts.paymentTableTitle.userName,
    texts.paymentTableTitle.paymentAmount,
    texts.paymentTableTitle.paymentDate,
    texts.paymentTableTitle.paymentDescription,
    texts.paymentTableTitle.paymentStatus,
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickDescription = (item) => {
    toast.info(item.paymentDescription, {
      autoClose: 10000,
      position: "top-center",
      hideProgressBar: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <Card className="w-full h-full shadow-2xl mx-4">
      <span className="px-4 py-4 text-[Inter] text-xl">{texts.payments}</span>
      <CardHeader>
        <TextField
          label={texts.search}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <MagnifyingGlassIcon className="h-5 w-5" />
            ),
          }}
          fullWidth
        />
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((head) => (
                    <TableCell key={head}>
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((item, index) => (
                  <TableRow key={item.paymentId}>
                    <TableCell>
                      <div className="flex items-center gap-3">
                        <span>
                          {item?.user?.userName + " " + item?.user?.userSurname}
                          <br />
                          {item?.user?.userFirmName}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>{item.paymentAmount}</TableCell>
                    <TableCell>
                      {item.paymentDate &&
                        moment(item.paymentDate).format("YYYY-MM-DD HH:mm")}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleClickDescription(item)}
                      >
                        {texts.paymentTableTitle.description}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <select
                        className={
                          "border border-blue-gray-200 rounded-md p-1" +
                          (item.status ? " text-green-500" : " text-red-500")
                        }
                        name="status"
                        id="status"
                        value={item.status ? "true" : "false"}
                        onChange={(e) => {
                          handleUpdatePaymentStatus(item, e.target.value);
                        }}
                      >
                        <option value="true" className="text-green-500">
                          {texts.printRequestTableTitle.printPaymentStatusPaid}
                        </option>
                        <option value="false" className="text-red-500">
                          {texts.printRequestTableTitle.printPaymentStatusUnpaid}
                        </option>
                      </select>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handlePageChange}
          />
        </CardActions>
    </Card>
  );
};

export default Index;