import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import { useLanguage } from "../../components/languageProvider";
import { toast } from "react-toastify";
import moment from "moment";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const [supportRequests, setSupportRequests] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [supportRequestMessage, setSupportRequestMessage] = useState({});

  const getAllSupportRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/supportRequest/getAllSupportRequest?page=${
          page - 1
        }&size=${size}&sort=supportRequestStatus,asc&sort=createdAt,desc`
      );
      const data = await response.json();
      console.log(data);
      if (!data) {
        toast.error(texts.paymentTableTitle.paymentNotFound);
        setPage(0);
      }
      setSupportRequests(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllSupportRequest();
  }, [page]);

  const TABLE_HEAD = [
    texts.supportRequstTableTitle.userName,
    texts.supportRequstTableTitle.supportRequestsMessage,
    texts.supportRequstTableTitle.supportRequestsDate,
    texts.supportRequstTableTitle.supportRequestsStatus,
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleUpdateRequestStatus = async (supportRequest, status) => {
    try {
      const updatePayment = {
        supportRequestId: supportRequest.supportRequestId,
        user: {
          userId: supportRequest.user.userId,
        },
        supportRequestStatus: status === "true" ? true : false,
        supportRequestDescription: supportRequest.supportRequestDescription,
        supportRequestDate: supportRequest.supportRequestDate,
      };

      const response = await fetch(
        `${API_URL}:${API_PORT}/supportRequest/saveSupportRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatePayment),
        }
      );
      const data = await response.json();
      if (data) {
        toast.success(texts.paymentTableTitle.paymentStatusUpdated);
        getAllSupportRequest();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleShowMessageDialog = (supportRequest) => {
    console.log(supportRequest);
    setSupportRequestMessage(supportRequest);
    setShowMessageDialog(true);
  };

  return (
    <Card className="w-full h-full shadow-2xl mx-4">
      <span className="px-4 py-4 text-[Inter] text-xl">{texts.payments}</span>
      <CardHeader>
        <TextField
          label={texts.search}
          variant="outlined"
          InputProps={{
            startAdornment: <MagnifyingGlassIcon className="h-5 w-5" />,
          }}
          fullWidth
        />
      </CardHeader>
      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((head) => (
                    <TableCell key={head}>{head}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {supportRequests?.map((item, index) => (
                  <TableRow key={item.paymentId}>
                    <TableCell>
                      <div className="flex items-center gap-3">
                        <span>
                          {item?.user?.userName + " " + item?.user?.userSurname}
                          <br />
                          {item?.user?.userFirmName}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {item.supportRequestDate &&
                        moment(item.supportRequestDate).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                    </TableCell>

                    <TableCell>
                      <select
                        className={
                          "border border-blue-gray-200 rounded-md p-1" +
                          (item.supportRequestStatus
                            ? " text-green-500"
                            : " text-red-500")
                        }
                        name="status"
                        id="status"
                        value={item.supportRequestStatus ? "true" : "false"}
                        onChange={(e) => {
                          handleUpdateRequestStatus(item, e.target.value);
                        }}
                      >
                        <option value="true" className="text-green-500">
                          {
                            texts.supportRequstTableTitle
                              .supportRequestsStatusCompleted
                          }
                        </option>
                        <option value="false" className="text-orange-500">
                          {
                            texts.supportRequstTableTitle
                              .supportRequestsStatusPending
                          }
                        </option>
                      </select>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleShowMessageDialog(item)}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={handlePageChange}
        />
      </CardActions>
      <Dialog
        open={showMessageDialog}
        onClose={() => setShowMessageDialog(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogContent sx={{ p: 4 }}>
          {/* Kullanıcı Bilgileri Bölümü */}
          <Box
            sx={{
              textAlign: "center",
              mb: 2,
              p: 2,
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
              }}
            >
              {texts.userModalTable.userDetails}
            </Typography>
            <List sx={{ mt: 1 }}>
              <ListItem disablePadding>
                <Typography variant="body1" color="textSecondary">
                  <strong>{texts.userModalTable.name}:</strong>{" "}
                  {supportRequestMessage?.user?.userName}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography variant="body1" color="textSecondary">
                  <strong>{texts.userModalTable.surname}:</strong>{" "}
                  {supportRequestMessage?.user?.userSurname}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography variant="body1" color="textSecondary">
                  <strong>{texts.userModalTable.companyName}:</strong>{" "}
                  {supportRequestMessage?.user?.userFirmName}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography variant="body1" color="textSecondary">
                  <strong>{texts.userModalTable.email}:</strong>{" "}
                  {supportRequestMessage?.user?.userEmail}
                </Typography>
              </ListItem>
              <ListItem disablePadding>
                <Typography variant="body1" color="textSecondary">
                  <strong>{texts.userModalTable.phone}:</strong>{" "}
                  {supportRequestMessage?.user?.userPhone}
                </Typography>
              </ListItem>
            </List>
          </Box>

          {/* Destek Mesajı Bölümü */}
          <Box
            sx={{
              mt: 2,
              p: 3,
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              boxShadow: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#333" }}
            >
              {texts.supportRequstTableTitle.supportRequestsMessage}
            </Typography>
            <Typography variant="body2" sx={{ color: "#555", mt: 1 }}>
              {supportRequestMessage?.supportRequestDescription}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowMessageDialog(false)}
            color="warning"
            variant="contained"
            sx={
              { fontFamily: "Inter" } // fontFamily: "Inter"
            }
          >
            {texts.close}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default Index;
