import Category from "./pages/category/index";
import Users from "./pages/users/index";
import AddUsers from "./pages/users/addUser/index";
import EditUser from "./pages/users/updateUser/index";
import Payments from "./pages/payments/index";
import SupportRequest from "./pages/supportrequest/index";
import Products from "./pages/products";
import Home from "./pages/homepage/index";
import Packages from "./pages/packages/index";
import AddPackage from "./pages/packages/addPackage/index";
import EditPackage from "./pages/packages/updatePackage/index";
import Dealer from "./pages/dealer/index";
import AddDealer from "./pages/dealer/AddDealer/index";
import BackgroundImages from "./pages/backgroundimages/index";
import AddBackgroundImage from "./pages/backgroundimages/addbackgroundimage/index";
import AddCategory from "./pages/category/addCategory/index";
import SendedCategoryImages from "./pages/category/sendedCategoryImages/index";
import AddImage from "./pages/category/addImage/index";
import ReviewSubCategory from "./pages/category/subCategoryReview/index";
import Print from "./pages/print/index";
import PrintPrices from "./pages/print/reviewPrices/index";
import AddPrice from "./pages/print/addPrice/index";
import DealerStatistics from "./pages/dealer/ShowDealerStatistics/index";
import SendenImages from "./pages/sendedImages/index";

const routes = [
  {
    path: "/dashboard",
    element: <Home />,
    canview: "everyone",
  },
  {
    path: "/packages",
    element: <Packages />,
    canview: "superadmin",
  },
  {
    path: "/packages/add-package",
    element: <AddPackage />,
    canview: "superadmin",
  },
  {
    path: "/packages/edit-package/:id",
    element: <EditPackage />,
    canview: "superadmin",
  },
  {
    path: "/products",
    element: <Products />,
    canview: "admin",
  },
  {
    path: "/support-request",
    element: <SupportRequest />,
    canview: "superadmin",
  },
  {
    path: "/background-images",
    element: <BackgroundImages />,
    canview: "superadmin",
  },
  {
    path: "/background-images/add",
    element: <AddBackgroundImage />,
    canview: "superadmin",
  },
  {
    path: "/payments",
    element: <Payments />,
    canview: "superadmin",
  },
  {
    path: "/users",
    element: <Users />,
    canview: "admin",
  },
  {
    path: "/users/add-user",
    element: <AddUsers />,
    canview: "admin",
  },
  {
    path: "/users/edit-user/:id",
    element: <EditUser />,
    canview: "admin",
  },
  { path: "/dealer", element: <Dealer />, canview: "superadmin" },
  {
    path: "/dealer/add-dealer",
    element: <AddDealer />,
    canview: "superadmin",
  },
  {
    path: "/dealer/add-dealer/:id",
    element: <AddDealer />,
    canview: "superadmin",
  },
  {
    path: "/dealer/dealer-statistics/:id",
    element: <DealerStatistics />,
    canview: "superadmin",
  },
  {
    path: "/category",
    element: <Category />,
    canview: "superadmin",
  },

  {
    path: "/category/addCategory",
    element: <AddCategory />,
    canview: "superadmin",
  },
  {
    path: "/category/editCategory/:id",
    element: <AddCategory />,
    canview: "superadmin",
  },
  {
    path: "/category/reviewSubCategory/:id",
    element: <ReviewSubCategory />,
    canview: "superadmin",
  },
  {
    path: "category/sendedCategoryImages",
    element: <SendedCategoryImages />,
    canview: "superadmin",
  },
  {
    path: "/category/addImage",
    element: <AddImage />,
    canview: "superadmin",
  },
  {
    path: "/print",
    element: <Print />,
    canview: "superadmin",
  },
  {
    path: "/print/review-prices",
    element: <PrintPrices />,
    canview: "superadmin",
  },
  {
    path: "/print/add-price",
    element: <AddPrice />,
    canview: "superadmin",
  },
  {
    path: "/print/add-price/:id",
    element: <AddPrice />,
    canview: "superadmin",
  },
  {
    path: "/support-request",
    element: <SupportRequest />,
    canview: "superadmin",
  },
  {
    path: "/sendedImages",
    element: <SendenImages />,
    canview: "superadmin",
  },
];
export default routes;
