import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  IconButton,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import { useLanguage } from "../../components/languageProvider";
import { ArrowDownward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import EmptyImage from "../../assets/images/emptyProduct.svg";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/nl";
import "moment/locale/tr";

const PriceName = ({ item, currentLanguage }) => {
  const printNames = {
    tr: item.printNameTr,
    de: item.printNameDe,
    fr: item.printNameFr,
    it: item.printNameIt,
    nl: item.printNameNl,
    en: item.printNameEn,
  };

  return (
    <Typography variant="body1" fontWeight="bold">
      {printNames[currentLanguage] || printNames.en}
    </Typography>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  moment.locale(currentLanguage);
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [printRequests, setPrintRequests] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const getAllPrintRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/printRequest/getAllPrintRequest?page=${page}&size=${size}&sort=requestStatus,desc`
      );
      const data = await response.json();
      setPrintRequests(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPrintRequest();
  }, [page]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleDownloadPrintImage = (imageName) => {
    fetch(`${API_URL}:${API_PORT}/${imageName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", imageName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const handleUpdateStatus = async (item, statusType, status) => {
    try {
      let updatePrintRequest = {
        printRequestId: item.printRequestId,
        user: {
          userId: item.user.userId,
        },
        print: {
          printId: item.print.printId,
        },
        product: {
          productId: item.product.productId,
        },
        requestDate: item.requestDate,
        requestDescription: item.requestDescription,
      };

      if (statusType === "requestStatus") {
        updatePrintRequest = {
          ...updatePrintRequest,
          requestStatus: status === "true" ? true : false,
          paymentStatus: item.paymentStatus,
        };
      } else if (statusType === "paymentStatus") {
        updatePrintRequest = {
          ...updatePrintRequest,
          requestStatus: item.requestStatus,
          paymentStatus: status === "true" ? true : false,
        };
      }

      const response = await fetch(
        `${API_URL}:${API_PORT}/printRequest/savePrintRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatePrintRequest),
        }
      );
      const data = await response.json();
      if (data) {
        getAllPrintRequest();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card sx={{ width: "100%", height: "100%", boxShadow: 3, m: 4, py: 4 }}>
      <div className="flex justify-between p-4">
        <Typography variant="h5" className="px-4">
          {texts.printerPage}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/print/review-prices")}
        >
          {texts.printRequestTableTitle.viewPrices}
        </Button>
      </div>
      <CardContent>
        {loading ? (
          <div className="flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {[
                    texts.printRequestTableTitle.printImage,
                    texts.printRequestTableTitle.printUser,
                    texts.printRequestTableTitle.printName,
                    texts.printRequestTableTitle.printPrice,
                    texts.printRequestTableTitle.printStatus,
                    texts.printRequestTableTitle.printPaymentStatus,
                    texts.printRequestTableTitle.printRequestDate,
                    "",
                  ].map((head) => (
                    <TableCell key={head} align="left">
                      <Typography variant="body2" fontWeight="bold">
                        {head}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {printRequests.map((item, index) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <img
                          src={
                            item?.product?.productImagePath
                              ? `${API_URL}:${API_PORT}/${item?.product?.productImagePath}`
                              : EmptyImage
                          }
                          alt={item?.product?.productName}
                          className="h-[105px] w-[70px] object-contain"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{item?.user?.userUsername}</Typography>
                      </TableCell>
                      <TableCell>
                        <PriceName
                          item={item?.print}
                          currentLanguage={currentLanguage}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{item?.print?.printPrice} €</Typography>
                      </TableCell>
                      <TableCell>
                        <Select
                          size="small"
                          value={item.requestStatus ? "true" : "false"}
                          onChange={(e) => {
                            handleUpdateStatus(
                              item,
                              "requestStatus",
                              e.target.value
                            );
                          }}
                          sx={{
                            color: item.requestStatus ? "orange" : "#0096FF", // Yazı rengi
                            borderColor: item.requestStatus
                              ? "orange"
                              : "#0096FF", // Kenar rengi
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: item.requestStatus
                                ? "orange"
                                : "#0096FF", // Select kenar çizgisi
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: item.requestStatus
                                ? "darkorange"
                                : "dark#0096FF", // Hover'da kenar çizgisi
                            },
                          }}
                        >
                          <MenuItem
                            value="false"
                            sx={{
                              color: "#0096FF", // Yazı rengi
                            }}
                          >
                            {texts.printRequestTableTitle.printStatusCompleted}
                          </MenuItem>
                          <MenuItem
                            value="true"
                            sx={{
                              color: "orange", // Yazı rengi
                            }}
                          >
                            {texts.printRequestTableTitle.printStatusPending}
                          </MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Select
                          value={item.paymentStatus ? "true" : "false"}
                          size="small"
                          onChange={(e) => {
                            handleUpdateStatus(
                              item,
                              "paymentStatus",
                              e.target.value
                            );
                          }}
                          sx={{
                            color: item.paymentStatus ? "green" : "red", // Yazı rengi duruma göre
                            borderColor: item.paymentStatus ? "green" : "red", // Kenar rengi duruma göre
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: item.paymentStatus ? "green" : "red", // Select kenar çizgisi
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: item.paymentStatus
                                ? "darkgreen"
                                : "darkred", // Hover'da kenar çizgisi
                            },
                          }}
                        >
                          <MenuItem
                            value="true"
                            sx={{
                              color: "green", // Ödenmiş - Yeşil renk
                            }}
                          >
                            {
                              texts.printRequestTableTitle
                                .printPaymentStatusPaid
                            }
                          </MenuItem>
                          <MenuItem
                            value="false"
                            sx={{
                              color: "red", // Ödenmemiş - Kırmızı renk
                            }}
                          >
                            {
                              texts.printRequestTableTitle
                                .printPaymentStatusUnpaid
                            }
                          </MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {moment(item?.requestDate).format("LL")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            handleDownloadPrintImage(
                              item?.product?.productImagePath
                            )
                          }
                        >
                          <ArrowDownward />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={handlePageChange}
        />
      </CardActions>
    </Card>
  );
};

export default Index;
