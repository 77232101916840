import { useState, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  Tooltip,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Pagination,
} from "@mui/material";
import { ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { languages } from "../../../constants/languages/languages";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { useLanguage } from "../../../components/languageProvider";
import EmptyImage from "../../../assets/images/noImage.svg";
import myToast from "../../../components/myToast";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SubCategoryName = ({ item, currentLanguage }) => {
  const subCategoryName = {
    tr: item.subCategoryNameTr,
    de: item.subCategoryNameDe,
    fr: item.subCategoryNameFr,
    it: item.subCategoryNameIt,
    nl: item.subCategoryNameNl,
    en: item.subCategoryNameEn,
  };
  return (
    <span style={{ fontWeight: "bold", fontFamily: "Inter" }}>
      {subCategoryName[currentLanguage] || subCategoryName.en}
    </span>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const [imageRequests, setImageRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [imageData, setImageData] = useState();
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const size = 10;

  const getImageRequests = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/imageRequest/getAllImageRequestWithType?type=categoryimages&page=${currentPage}&size=${size}&sort=requestStatus,desc&sort=createdAt,desc`
      );
      const data = await response.json();
      setImageRequests(data?.content);
      console.log(data);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getImageRequests();
  }, [currentPage]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage - 1); // MUI Pagination's page starts from 1
  };

  const handleDownloadSendedImage = (imageName) => {
    fetch(`${API_URL}:${API_PORT}/${imageName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", imageName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const handleDeleteImageRequest = async (item) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/imageRequest/deleteImageRequest?imageRequestId=${item?.imageRequestId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        myToast({
          status: "success",
          message: texts.successMessages.deleteSuccess,
        });
        getImageRequests();
      } else {
        myToast({
          status: "error",
          message: texts.successMessages.deleteError,
        });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.successMessages.deleteError });
    }
  };

  const handleOpenUpdateImage = (item) => {
    setOpenUpdateDialog(true);
    setSelectedProduct(item);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      myToast({ status: "error", message: texts.fileExtensionError });
      return;
    }

    setImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageData = reader.result;
      setImageData(imageData);
    };
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image);
      const categorytype = "subcategoryimages";
      const response = await fetch(
        `${API_URL}:${API_PORT}/image/uploadImage?imageType=${categorytype}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  const handleRelationCategoryAndImage = async (imageId) => {
    try {
      const ENDPOINT = "ImageSubCategoryRelation/saveImageSubCategoryRelation";
      const relation = {
        image: {
          imageId: imageId,
        },
        subCategory: {
          subCategoryId: selectedProduct?.subCategory?.subCategoryId,
        },
        status: true,
      };
      const response = await fetch(`${API_URL}:${API_PORT}/${ENDPOINT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(relation),
      });

      if (!response.ok) {
        throw new Error(texts.categoryImageRelationError);
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  const saveImage = async () => {
    try {
      const imageResponse = await uploadImage();
      if (imageResponse && imageResponse?.imageId) {
        const relationResponse = await handleRelationCategoryAndImage(
          imageResponse?.imageId
        );
        if (relationResponse.ok) {
          myToast({ status: "success", message: texts.categorySaveSuccess });
          navigate(
            "/category/reviewSubCategory/" +
              selectedProduct?.subCategory?.subCategoryId
          );
        } else {
          myToast({
            status: "error",
            message: texts.categoryImageRelationError,
          });
        }
      } else {
        myToast({ status: "error", message: texts.categorySaveError });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.categorySaveError });
    }
  };

  const handleUpdateRequestStatus = async (supportRequest, status) => {
    try {
      const updatePayment = {
        imageRequestId: supportRequest?.imageRequestId,
        user: {
          userId: supportRequest?.user?.userId,
        },
        image: {
          imageId: supportRequest?.image?.imageId,
        },
        requestType: supportRequest?.requestType,
        subCategory: {
          subCategoryId: supportRequest?.subCategory?.subCategoryId,
        },
        requestDate: supportRequest?.requestDate,
        requestStatus: status === "true" ? true : false,
        requestDescription: supportRequest?.requestDescription,
      };

      const response = await fetch(
        `${API_URL}:${API_PORT}/imageRequest/saveImageRequest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatePayment),
        }
      );
      const data = await response.json();
      if (data) {
        toast.success(texts.paymentTableTitle.paymentStatusUpdated);
        getImageRequests();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <CircularProgress size={40} />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl mx-4">
          <h2 className="px-4 py-2 text-xl">{texts.sendedCategoryImages}</h2>
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{texts.categoryImage}</TableCell>
                    <TableCell>{texts.userTableTitle.userName}</TableCell>
                    <TableCell>{texts.userTableTitle.phone}</TableCell>
                    <TableCell>{texts.category}</TableCell>
                    <TableCell>{texts.status}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {imageRequests?.map((item, index) => (
                    <TableRow key={item.categoryId}>
                      <TableCell>
                        <img
                          src={
                            item?.image?.imageName
                              ? `${API_URL}:${API_PORT}/${item?.image?.imageName}`
                              : EmptyImage
                          }
                          alt={item?.categoryNameEn}
                          style={{
                            width: 80,
                            objectFit: "cover",
                            borderRadius: 8,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <strong>
                          {item?.user?.userName + " " + item?.user?.userSurname}
                        </strong>
                        <br />
                        {item?.user?.userFirmName}
                      </TableCell>
                      <TableCell>
                        <strong>{item?.user?.userPhone}</strong>
                      </TableCell>
                      <TableCell>
                        <SubCategoryName
                          item={item?.subCategory}
                          currentLanguage={currentLanguage}
                        />
                      </TableCell>
                      <TableCell>
                        <select
                          className={
                            "border border-blue-gray-200 rounded-md p-1" +
                            (item.requestStatus
                              ? " text-green-500"
                              : " text-red-500")
                          }
                          name="status"
                          id="status"
                          value={item.requestStatus ? "true" : "false"}
                          onChange={(e) => {
                            handleUpdateRequestStatus(item, e.target.value);
                          }}
                        >
                          <option value="true" className="text-green-500">
                            {
                              texts.supportRequstTableTitle
                                .supportRequestsStatusCompleted
                            }
                          </option>
                          <option value="false" className="text-orange-500">
                            {
                              texts.supportRequstTableTitle
                                .supportRequestsStatusPending
                            }
                          </option>
                        </select>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={texts.downloadSendedImage}>
                          <IconButton
                            color="primary"
                            onClick={() =>
                              handleDownloadSendedImage(item?.image?.imageName)
                            }
                          >
                            <ArrowDownward />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={texts.uploadEditedImage}>
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenUpdateImage(item)}
                          >
                            <ArrowUpward />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={texts.deleteImage}>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteImageRequest(item)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <Pagination
              count={totalPages}
              page={currentPage + 1}
              onChange={handlePageChange}
            />
          </CardActions>
        </Card>
      )}
      <Dialog
        open={openUpdateDialog}
        onClose={() => setOpenUpdateDialog(false)}
      >
        <DialogTitle>{texts.uploadEditedImage}</DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <img
              style={{
                height: 300,
                width: 300,
                objectFit: "cover",
                borderRadius: 16,
              }}
              src={imageData || "https://placehold.co/300x300"}
              alt="categoryImage"
            />
            <input
              type="file"
              accept="image/jpeg, image/png, image/jpg, image/heic"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => fileInputRef.current.click()}
              style={{ marginTop: 16 }}
            >
              {texts.addImage}
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setOpenUpdateDialog(false)}
          >
            {texts.no}
          </Button>
          <Button variant="contained" color="primary" onClick={saveImage}>
            {texts.yes}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Index;
