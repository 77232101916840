import { languages } from "../../../constants/languages/languages";
import React, { useRef, useState, useEffect } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { countryOptions } from "../../../constants/countries";
import {
  Card,
  CardContent,
  Checkbox,
  Button,
  TextField,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
};

const getPackageName = (pkg, currentLanguage) => {
  const packageName = {
    tr: pkg.packageNameTr,
    de: pkg.packageNameDe,
    fr: pkg.packageNameFr,
    it: pkg.packageNameIt,
    nl: pkg.packageNameNl,
    en: pkg.packageNameEn,
  };

  return packageName[currentLanguage] || packageName.en;
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [image, setImage] = useState({
    imageId: "",
  });
  const [user, setUser] = useState({
    userName: "",
    userSurname: "",
    userUsername: "",
    userPassword: "",
    userRole: "user",
    userTrial: false,
    userEmail: "",
    userPhone: "",
    userFirmName: "",
    userTaxNumber: "",
    userSelectedBg: "",
    userCountry: "",
    userPostalCode: "",
    image: image,
  });
  const [userPackage, setUserPackage] = useState({ packageId: "" });
  const [userPackageRelation, setUserPackageRelation] = useState({
    userDealerRelId: "",
    user: user,
    startDate: "",
    endDate: "",
    isActive: true,
    isSelectedPackageMonth: 1,
    apackage: userPackage,
  });
  const [userBankAccount, setUserBankAccount] = useState({
    accountNumber: "",
    iban: "",
    bankName: "",
    bankAccountUserName: "",
    bankAccountUserSurname: "",
  });
  const [isSelectedPackageMonth, setIsSelectedPackageMonth] = useState(1);
  const [relation, setRelation] = useState(null);
  const [changeImage, setChangeImage] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [packages, setPackages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [userSaving, setUserSaving] = useState(false);

  const fileInputRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const getAllPackage = async () => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/package/getAllPackageWithBg?page=0&size=9999999`
      );
      const data = await response.json();
      setPackages(data?.content || []);
    } catch (error) {
      console.log(error);
    }
  };
  const handleImageSelection = (imageId) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(imageId)) {
        return prevSelectedImages.filter((id) => id !== imageId);
      } else {
        return [...prevSelectedImages, imageId];
      }
    });
  };
  const fetchUser = async () => {
    if (id) {
      try {
        const response = await fetch(
          `${API_URL}:${API_PORT}/ImageUserRelation/findImageByUserIdAndImageType?userId=${id}&imageType=userimages`
        );
        const data = await response.json();
        if (data?.content?.length > 0) {
          const content = data?.content[0];
          setRelation(content);
          setUser(content?.user);
          setUserPackage(content?.userPackageRelation?.apackage);
          console.log(content);
          setUserPackageRelation(content?.userPackageRelation);

          setUserBankAccount(content?.bankAccount);
          setSelectedImages(content?.user?.userSelectedBg.split(","));
          setImage(content.image);
        }
      } catch (error) {
        myToast({ status: "error", message: texts.fetchUserError });
      } finally {
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        // Paketleri al (asenkron olarak çalıştır)
        getAllPackage();

        await fetchUser();
      } catch (error) {
        console.error("Error fetching data:", error);
        myToast({ status: "error", message: texts.fetchUserError });
      } finally {
      }
    };

    fetchData();
    setTimeout(() => {
      setLoading(false); // 3 saniye sonra loading'i kapat
    }, 3000);
  }, [id, API_URL, API_PORT]);

  const handleSelectPackageChange = (selectedOption) => {
    const selectedPackage = packages.find(
      (pkg) => pkg.packageId === selectedOption.value
    );
    setUserPackage(selectedPackage);
  };

  const packageOptions = packages.map((pkg) => ({
    value: pkg.packageId,
    label: getPackageName(pkg, currentLanguage),
  }));

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      myToast({ status: "error", message: texts.fileExtensionError });
      return;
    }

    setNewImage(file);
    setChangeImage(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append("file", newImage);
      const categorytype = "userimages";
      const response = await fetch(
        `${API_URL}:${API_PORT}/image/uploadImage?imageType=${categorytype}`,
        {
          method: "POST",
          body: formData,
        }
      );
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  const updateUserImageAndUserRelation = async (imageId) => {
    try {
      const payload = {
        imageUserRelId: relation.imageUserRelId,
        image: {
          imageId: imageId,
        },
        user: {
          userId: user.userId,
          userName: user.userName,
          userSurname: user.userSurname,
          userUsername: user.userUsername,
          userPassword: user.userPassword,
          userRole: user.userRole,
          userRegistrationDate: user.userRegistrationDate,
          userTrial: user.userTrial,
          userEmail: user.userEmail,
          userPhone: user.userPhone,
          userFirmName: user.userFirmName,
          userTaxNumber: user.userTaxNumber,
          userCountry: user.userCountry,
          userPostalCode: user.userPostalCode,
          dealer: null,
          userSelectedBg: selectedImages.join(","),
        },
        bankAccount: {
          bankAccountId: userBankAccount.bankAccountId,
          user: {
            userId: user.userId,
          },
          accountNumber: userBankAccount.accountNumber,
          iban: userBankAccount.iban,
          bankName: userBankAccount.bankName,
          bankAccountUserName: userBankAccount.bankAccountUserName,
          bankAccountUserSurname: userBankAccount.bankAccountUserSurname,
        },
        userPackageRelation: {
          userPackageRelId: userPackageRelation.userPackageRelId,
          user: {
            userId: user.userId,
          },
          startDate: userPackageRelation.startDate,
          endDate: userPackageRelation.endDate,
          isActive: userPackageRelation.isActive,
          isSelectedPackageMonth: isSelectedPackageMonth,
          apackage: {
            packageId: userPackage.packageId,
          },
        },

        imageType: "userimages",
        status: true,
        singleOrMultiple: true,
      };

      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageUserRelation/updateImageUserRelation`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      return await response.json();
    } catch (error) {
      myToast({ status: "error", message: texts.saveStatus.relationSaveError });
    }
  };

  const updateUserAndImage = async () => {
    try {
      if (changeImage) {
        const imageResponse = await uploadImage();
        if (imageResponse?.imageId) {
          const relationResponse = await updateUserImageAndUserRelation(
            imageResponse.imageId,
            user.userId
          );
          console.log(relationResponse);
          if (relationResponse) {
            myToast({
              status: "success",
              message: texts.saveStatus.userSaveSuccess,
            });
            navigate("/users");
          } else {
            myToast({
              status: "error",
              message: texts.saveStatus.relationSaveError,
            });
          }
        } else {
          myToast({
            status: "error",
            message: texts.saveStatus.imageSaveError,
          });
        }
      } else {
        const relationResponse = await updateUserImageAndUserRelation(
          relation.image.imageId,
          user.userId
        );

        if (relationResponse) {
          myToast({
            status: "success",
            message: texts.saveStatus.userSaveSuccess,
          });
          navigate("/users");
        } else {
          myToast({
            status: "error",
            message: texts.saveStatus.relationSaveError,
          });
        }
      }
    } catch (error) {
      myToast({ status: "error", message: texts.categorySaveError });
    }
  };

  const handleSave = async () => {
    setUserSaving(true);
    if (user.userId) {
      await updateUserAndImage();
    }
    setUserSaving(false);
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <CircularProgress size={100} />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl p-4">
          <h2>{texts.userTableTitle.addUser}</h2>
          <div className="rounded-none gap-8">
            <div className="relative h-72 w-full flex justify-center">
              <img
                className="h-[300px] w-[300px] rounded-xl object-cover object-center shadow-xl"
                src={
                  imageData
                    ? imageData
                    : image?.imageName
                    ? `${API_URL}:${API_PORT}/${image?.imageName}`
                    : "https://placehold.co/1000x1000"
                }
                alt="categoryImage"
              />
              <input
                type="file"
                id="file-upload"
                accept="image/jpeg, image/png, image/jpg, image/heic"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              <label
                htmlFor="file-upload"
                className="absolute font-[Inter] flex items-center justify-center cursor-pointer"
                onClick={triggerFileInput}
              >
                <Button
                  sx={{
                    width: "300px",
                    borderRadius: "0px",
                    opacity: 0.75,
                  }}
                  variant="contained"
                  color="primary"
                >
                  {texts.addImage}
                </Button>
              </label>
            </div>
          </div>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={user.userTrial || false}
                  onChange={(e) =>
                    setUser({ ...user, userTrial: e.target.checked })
                  }
                />
              }
              label={texts.userTableTitle.freeTrial}
            />

            <TextField
              size="small"
              label={texts.userTableTitle.name}
              onChange={(e) => setUser({ ...user, userName: e.target.value })}
              value={user.userName || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.surname}
              onChange={(e) =>
                setUser({ ...user, userSurname: e.target.value })
              }
              value={user.userSurname || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userName}
              onChange={(e) =>
                setUser({ ...user, userUsername: e.target.value })
              }
              value={user.userUsername || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userTaxNumber}
              onChange={(e) =>
                setUser({ ...user, userTaxNumber: e.target.value })
              }
              value={user.userTaxNumber || ""}
              fullWidth
              type="number"
            />
            <TextField
              size="small"
              label={texts.userTableTitle.phone}
              onChange={(e) => setUser({ ...user, userPhone: e.target.value })}
              value={user.userPhone || ""}
              fullWidth
              type="number"
            />
            <TextField
              size="small"
              label={texts.userTableTitle.email}
              onChange={(e) => setUser({ ...user, userEmail: e.target.value })}
              value={user.userEmail || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userPassword}
              onChange={(e) =>
                setUser({ ...user, userPassword: e.target.value })
              }
              value={user.userPassword || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.companyName}
              onChange={(e) =>
                setUser({ ...user, userFirmName: e.target.value })
              }
              value={user.userFirmName || ""}
              fullWidth
              type="email"
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userIBAN}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  iban: e.target.value,
                })
              }
              value={userBankAccount.iban || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userBankAccountOwnerName}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  bankAccountUserName: e.target.value,
                })
              }
              value={userBankAccount.bankAccountUserName || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userBankAccountOwnerSurname}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  bankAccountUserSurname: e.target.value,
                })
              }
              value={userBankAccount.bankAccountUserSurname || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userBankName}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  bankName: e.target.value,
                })
              }
              value={userBankAccount.bankName || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userAccountNumber}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  accountNumber: e.target.value,
                })
              }
              value={userBankAccount.accountNumber || ""}
              fullWidth
              type="number"
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userZipCode}
              onChange={(e) =>
                setUser({ ...user, userPostalCode: e.target.value })
              }
              value={user.userPostalCode || ""}
              fullWidth
              inputProps={{ maxLength: 5 }}
              type="number"
            />
            <Select
              options={countryOptions}
              styles={customStyles}
              value={countryOptions.find(
                (option) => option.value === user.userCountry
              )} // Seçilen ülkenin option objesini bul
              onChange={(e) => setUser({ ...user, userCountry: e.value })} // Seçilen ülkenin id'sini kaydet
              isSearchable
              className="font-[Inter] w-full"
            />

            <Select
              className="w-full h-10 rounded-lg"
              value={{
                value: userPackage.packageId,
                label: getPackageName(userPackage, currentLanguage),
              }}
              onChange={handleSelectPackageChange}
              options={packageOptions}
              placeholder="Select a package..."
            />

            {userPackage && userPackage.packageImages && (
              <div className="image-selection flex flex-wrap gap-4 mt-4">
                {userPackage.packageImages.map((item) => (
                  <div
                    key={item.imageId}
                    onClick={() => handleImageSelection(item.imageId)}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      border: "2px solid",
                      borderColor: selectedImages.includes(item.imageId)
                        ? "blue"
                        : "gray",
                      borderRadius: "8px",
                      overflow: "hidden",
                      width: "100px",
                      display: "inline-block",
                    }}
                  >
                    <img
                      src={`${API_URL}:${API_PORT}/${item.imageName}`}
                      alt={item.imageName}
                      style={{
                        display: "block",
                        width: "100px",
                        transition: "opacity 0.3s ease",
                        opacity: selectedImages.includes(item.imageId)
                          ? 0.6
                          : 1,
                        objectFit: "cover",
                      }}
                    />
                    {selectedImages.includes(item.imageId) && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "white",
                          fontSize: "24px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          padding: "5px 10px",
                          borderRadius: "5px",
                        }}
                      >
                        ✓
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <Button
              color="blue"
              className="w-full font-[Inter]"
              onClick={handleSave}
              loading={userSaving}
            >
              {texts.userTableTitle.updateUser}
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Index;
