import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Button,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Pagination,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { languages } from "../../constants/languages/languages";
import { useLanguage } from "../../components/languageProvider";
import { useParams, useNavigate } from "react-router-dom";
import myToast from "../../components/myToast";

const SubCategoryName = ({ item, currentLanguage }) => {
  const subCategoryName = {
    tr: item.subCategoryNameTr,
    de: item.subCategoryNameDe,
    fr: item.subCategoryNameFr,
    it: item.subCategoryNameIt,
    nl: item.subCategoryNameNl,
    en: item.subCategoryNameEn,
  };

  return (
    <span style={{ fontWeight: "bold" }}>
      {subCategoryName[currentLanguage] || subCategoryName.en}
    </span>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];

  const [mainCategory, setMainCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [headerText, setHeaderText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const size = 7;
  const [page, setPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const TABLE_HEAD = ["DE", "NL", "EN", "TR", "FR", "IT", ""];

  const getMainCategory = async () => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/category/getAllCategory?page=0&size=9999`
      );
      const data = await response.json();
      return data?.content[0];
    } catch (error) {
      throw error;
    }
  };

  const getSubCategoriesByCategoryId = async (categoryId, page, size) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/subCategory/findByLikeCategoryId?categoryId=${categoryId}&page=${page}&size=${size}&sort=subCategoryNameDe,asc`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const categoryData = await getMainCategory();
        setMainCategory(categoryData);

        const lang = currentLanguage || "en";
        setHeaderText(
          categoryData?.[
            `categoryName${lang.charAt(0).toUpperCase() + lang.slice(1)}`
          ] || categoryData?.categoryNameEn
        );

        const subCategoryData = await getSubCategoriesByCategoryId(
          categoryData?.categoryId,
          page,
          size
        );
        setSubCategories(subCategoryData?.content);
        setTotalPages(subCategoryData?.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, page, size, currentLanguage]);

  const handleOpenDeleteModal = (subCategory) => {
    setSelectedSubCategory(subCategory);
    setDeleteModal(true);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleDeleteSubCategory = async (subCategoryId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/subCategory/deleteSubCategory?subCategoryId=${subCategoryId}`,
        { method: "DELETE" }
      );
      if (response.status === 200) {
        setDeleteModal(false);
        setSubCategories((prev) =>
          prev.filter((item) => item.subCategoryId !== subCategoryId)
        );
        myToast({
          status: "success",
          message: texts.addCategoryItems.categoryDeleteSuccess,
        });
      } else {
        myToast({
          status: "error",
          message: texts.addCategoryItems.categoryDeleteError,
        });
      }
      setDeleteModal(false);
    } catch (error) {
      myToast({
        status: "error",
        message: texts.addCategoryItems.categoryDeleteError,
      });
      setDeleteModal(false);
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={60} />
        </div>
      ) : (
        <Card>
          <CardContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <h2>{headerText}</h2>
              <div style={{ alignSelf: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => navigate("/category/addCategory")}
                >
                  {texts.addCategory}
                </Button>
              </div>
              <TableContainer
                sx={{
                  height: "calc(100vh - 300px)",
                  width: "calc(100vw - 100px)",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((head, index) => (
                        <TableCell key={index}>{head}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subCategories.map((item, index) => (
                      <TableRow key={item.categoryId}>
                        <TableCell>{item.subCategoryNameDe}</TableCell>
                        <TableCell>{item.subCategoryNameNl}</TableCell>
                        <TableCell>{item.subCategoryNameEn}</TableCell>
                        <TableCell>{item.subCategoryNameTr}</TableCell>
                        <TableCell>{item.subCategoryNameFr}</TableCell>
                        <TableCell>{item.subCategoryNameIt}</TableCell>
                        <TableCell>
                          <Tooltip title={texts.reviewCategory}>
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/category/reviewSubCategory/${item.subCategoryId}`
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={texts.editCategory}>
                            <IconButton
                              onClick={() =>
                                navigate(
                                  `/category/editCategory/${item.subCategoryId}`
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={texts.deleteCategory}>
                            <IconButton
                              onClick={() => handleOpenDeleteModal(item)}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <Pagination
              count={totalPages}
              page={page + 1}
              onChange={handlePageChange}
            />
          </CardActions>
          <Dialog open={deleteModal} onClose={() => setDeleteModal(false)}>
            <DialogTitle>
              <SubCategoryName
                item={selectedSubCategory}
                currentLanguage={currentLanguage}
              />
            </DialogTitle>
            <DialogContent>
              <p>{texts.addCategoryItems.subCategoryDeleteInfo}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteModal(false)}>
                {texts.addCategoryItems.deleteSubCategoryCancel}
              </Button>
              <Button
                color="error"
                onClick={() =>
                  handleDeleteSubCategory(selectedSubCategory.subCategoryId)
                }
              >
                {texts.addCategoryItems.deleteSubCategory}
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      )}
    </>
  );
};

export default Index;
