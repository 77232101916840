import React from "react";
import DasboardItem from "../../components/DasboardItem";
import {
  ArchiveBoxIcon,
  ListBulletIcon,
  RectangleStackIcon,
  UserGroupIcon,
  BanknotesIcon,
  BuildingOffice2Icon,
  ChatBubbleLeftRightIcon,
  PhotoIcon,
  ArrowUpOnSquareStackIcon,
  PrinterIcon,
  CloudArrowUpIcon,
} from "@heroicons/react/24/outline";
import { languages } from "../../constants/languages/languages";
import bgLogo from "../../assets/images/backgroundLogo.svg";
import CanViewItem from "../../components/CanViewItem";
import { useLanguage } from "../../components/languageProvider";
import Select from "react-select";
import { countryOptions } from "../../constants/countries";
import Box from "@mui/material/Box";

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
};

const Index = () => {
  const { currentLanguage, changeLanguage } = useLanguage();

  const texts = languages[currentLanguage];
  const dashbordItems = [
    {
      title: texts.products,
      link: "/products",
      icon: <ArchiveBoxIcon className="w-12 h-12 text-blue-500" />, // Mavi
      canview: "superadmin",
    },
    {
      title: texts.categories,
      link: "/category",
      icon: <ListBulletIcon className="w-12 h-12 text-green-500" />, // Yeşil
      canview: "superadmin",
    },
    {
      title: texts.sendedCategoryImages,
      link: "/category/sendedCategoryImages",
      icon: <ArrowUpOnSquareStackIcon className="w-12 h-12 text-orange-500" />, // Turuncu
      canview: "superadmin",
    },
    {
      title: texts.sendedImages,
      link: "/sendedImages",
      icon: (
        <CloudArrowUpIcon className="w-12 h-12 text-light-blue-400" />
      ), // Açık mavi
      canview: "superadmin",
    },
    {
      title: texts.users,
      link: "/users",
      icon: <UserGroupIcon className="w-12 h-12 text-purple-500" />, // Mor
      canview: "admin",
    },
    {
      title: texts.payments,
      link: "/payments",
      icon: <BanknotesIcon className="w-12 h-12 text-yellow-500" />, // Sarı
      canview: "superadmin",
    },
    {
      title: texts.dealer,
      link: "/dealer",
      icon: <BuildingOffice2Icon className="w-12 h-12 text-red-500" />, // Kırmızı
      canview: "superadmin",
    },
    {
      title: texts.supportRequests,
      link: "/support-request",
      icon: <ChatBubbleLeftRightIcon className="w-12 h-12 text-teal-500" />, // Su yeşili
      canview: "admin",
    },
    {
      title: texts.userBackgrondImages,
      link: "/background-images",
      icon: <PhotoIcon className="w-12 h-12 text-indigo-500" />, // İndigo
      canview: "admin",
    },
    {
      title: texts.packages,
      link: "/packages",
      icon: <RectangleStackIcon className="w-12 h-12 text-gray-500" />, // Gri
      canview: "superadmin",
    },
    {
      title: texts.printerPage,
      link: "/print",
      icon: <PrinterIcon className="w-12 h-12 text-pink-500" />, // Pembe
      canview: "superadmin",
    },
  ];

  return (
    <>
      <div className="relative w-full h-full mx-4 rounded-2xl px-12 py-16 flex items-start justify-start gap-4 flex-col">
        <div className="absolute inset-0 pt-36 flex items-center justify-center z-[-1]">
          <img
            src={bgLogo}
            alt="Background"
            className="w-2/3 h-auto object-cover opacity-30 rounded-2xl"
          />
        </div>
        <div className="relative z-10">
          <span className="text-[Inter] pl-4 text-3xl">
            {texts.welcomeToDashboard + ", A-FLYING!"}
          </span>
        </div>
        <Box
          sx={{
            padding: 2,
            textAlign: "center",
            width: "94%",
            borderTop: "1px solid #ddd",
          }}
        >
          <Select
            options={countryOptions}
            styles={customStyles}
            value={countryOptions.find(
              (country) => country.value === currentLanguage
            )} // Seçilen ülkenin option objesini bul
            onChange={(e) => changeLanguage(e.value)} // Seçilen ülkenin id'sini kaydet
            isSearchable
            className="font-[Inter] w-64 z-50"
          />
        </Box>
        <div className="flex flex-row flex-wrap gap-9 justify-start items-center relative z-10">
          {dashbordItems.map((item, index) => (
            <CanViewItem key={index} canview={item.canview}>
              <DasboardItem
                title={item.title}
                link={item.link}
                icon={item.icon}
              />
            </CanViewItem>
          ))}
        </div>
      </div>
    </>
  );
};

export default Index;
