import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import GroupIcon from "@mui/icons-material/Group";
import PaymentsIcon from "@mui/icons-material/Payments";
import StoreIcon from "@mui/icons-material/Store";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import CategoryIcon from "@mui/icons-material/Category";
import HomeIcon from "@mui/icons-material/Home";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import logo from "../assets/images/logo.svg";
import { useLanguage } from "./languageProvider";
import CanViewItem from "./CanViewItem";
import { languages } from "../constants/languages/languages";
import Select from "react-select";
import { countryOptions } from "../constants/countries";
import MenuIcon from "@mui/icons-material/Menu";
import { AutoFixHigh } from "@mui/icons-material";

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
};

const Leftmenu = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const leftMenuItems = languages[currentLanguage];
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const handleUpdateDrawerStatus = (item, status) => {
    if (item?.onClick) item.onClick(); // İlgili sayfaya yönlendirme
    setOpen(status); // Drawer durumunu güncelle
  };

  const drawerList = [
    {
      id: 1,
      name: leftMenuItems.homePage,
      icon: <HomeIcon className="w-6 h-6 text-blue-500" />, // Mavi
      canview: "superadmin",
      onClick: () => navigate("/"),
    },
    {
      id: 2,
      name: leftMenuItems.products,
      icon: <Inventory2Icon className="w-6 h-6 text-green-500" />, // Yeşil
      canview: "superadmin",
      onClick: () => navigate("/products"),
    },
    {
      id: 3,
      name: leftMenuItems.categoriesAndImages,
      icon: <CategoryIcon className="w-6 h-6 text-orange-500" />, // Turuncu
      canview: "superadmin",
      onClick: () => navigate("/category"),
    },
    {
      id: 4,
      name: leftMenuItems.sendedCategoryImages,
      icon: <SendToMobileIcon className="w-6 h-6 text-purple-500" />, // Mor
      canview: "superadmin",
      onClick: () => navigate("category/sendedCategoryImages"),
    },
    {
      id: 5,
      name: leftMenuItems.sendedImages,
      icon: <AutoFixHigh className="w-6 h-6 text-blue-500" />, // Kırmızı
      canview: "superadmin",
      onClick: () => navigate("/sendedImages"),
    },
    {
      id: 6,
      name: leftMenuItems.dealer,
      icon: <StoreIcon className="w-6 h-6 text-red-500" />, // Kırmızı
      canview: "superadmin",
      onClick: () => navigate("/dealer"),
    },
    {
      id: 7,
      name: leftMenuItems.payments,
      icon: <PaymentsIcon className="w-6 h-6 text-yellow-500" />, // Sarı
      canview: "superadmin",
      onClick: () => navigate("/payments"),
    },
    {
      id: 8,
      name: leftMenuItems.users,
      icon: <GroupIcon className="w-6 h-6 text-teal-500" />, // Su yeşili
      canview: "superadmin",
      onClick: () => navigate("/users"),
    },
    {
      id: 9,
      name: leftMenuItems.packages,
      icon: <BackupTableIcon className="w-6 h-6 text-indigo-500" />, // İndigo
      canview: "superadmin",
      onClick: () => navigate("/packages"),
    },
    {
      id: 10,
      name: leftMenuItems.userBackgrondImages,
      icon: <PhotoSizeSelectActualIcon className="w-6 h-6 text-gray-500" />, // Gri
      canview: "superadmin",
      onClick: () => navigate("/background-images"),
    },
    {
      id: 11,
      name: leftMenuItems.printerPage,
      icon: <LocalPrintshopIcon className="w-6 h-6 text-pink-500" />, // Pembe
      canview: "superadmin",
      onClick: () => navigate("/print"),
    },
    {
      id: 12,
      name: leftMenuItems.supportRequests,
      icon: <MarkUnreadChatAltIcon className="w-6 h-6 text-blue-600" />, // Daha koyu mavi
      canview: "superadmin",
      onClick: () => navigate("/support-request"),
    },
  ];

  const DrawerList = (
    <Box sx={{ width: 300 }} role="presentation">
      <div className="text-center py-4">
        <img src={logo} alt="Logo" className="mx-auto w-20 h-20" />
        <h2 className="text-2xl font-bold">FLYING.COM</h2>
      </div>
      <List>
        {drawerList.map((item) => (
          <CanViewItem canview="superadmin" key={item.id}>
            <ListItem key={item.id} disablePadding>
              <ListItemButton
                onClick={() => handleUpdateDrawerStatus(item, false)} // Drawer’ı kapat ve yönlendir
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          </CanViewItem>
        ))}
      </List>
      <Box
        sx={{ padding: 2, textAlign: "center", borderTop: "1px solid #ddd" }}
      >
        <Select
          options={countryOptions}
          styles={customStyles}
          value={countryOptions.find(
            (country) => country.value === currentLanguage
          )}
          onChange={(e) => changeLanguage(e.value)}
          isSearchable
          className="font-[Inter] w-full"
        />
      </Box>
    </Box>
  );

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={toggleDrawer(true)}
        startIcon={<MenuIcon />} // Menü iconunu sola ekliyoruz
        sx={{
          textTransform: "none", // Buton metnini küçük harfli bırak
          fontWeight: "bold", // Kalın metin
          padding: "8px 16px", // Daha geniş bir padding
          backgroundColor: "#3572EF", // Buton rengi
          color: "white", // Buton metin rengi
          borderRadius: "8px", // Köşeleri yuvarlat
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Hafif bir gölge
          "&:hover": {
            backgroundColor: "#050C9C", // Hover'da buton rengini değiştir
          },
        }}
      >
        {leftMenuItems.menu}
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default Leftmenu;
