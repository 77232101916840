import React, { useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { languages } from "../../../constants/languages/languages";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage].addCategoryItems;
  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState();
  const [imageData, setImageData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fileInputRef = useRef(null); // useRef ile bir referans oluşturuluyor

  const location = useLocation();

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const subCategoryId = searchParams.get("subCategoryId");
    const subCategoryName = searchParams.get("subCategoryName");
    return {  subCategoryId,  subCategoryName };
  };

  const {  subCategoryId,  subCategoryName } =
    getQueryParams();

  const triggerFileInput = () => {
    fileInputRef.current.click(); // Butona tıklandığında dosya seçme inputunu tetikletiyoruz
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0]; // Yüklenen dosyayı alın

    // Dosya uzantısını kontrol etme
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      // Uzantı JPEG veya PNG değilse, işlemi durdur
      myToast({ status: "error", message: texts.fileExtensionError });
      return;
    }

    // Resmi base64'e çevirme işlemi
    setImage(file); // Resmi state içerisine atama
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageData = reader.result; // Resmi base64 olarak alın
      setImageData(imageData); // Resmi state içerisine atama
    };
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append("file", image);
      const categorytype = "subcategoryimages";
      const response = await fetch(
        `${API_URL}:${API_PORT}/image/uploadImage?imageType=${categorytype}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  const handleRelationCategoryAndImage = async (imageId) => {
    try {
      const ENDPOINT = "ImageSubCategoryRelation/saveImageSubCategoryRelation";
      var relation = {
        image: {
          imageId: imageId,
        },
        subCategory: {
          subCategoryId: subCategoryId,
        },
        status: true,
      };
      const response = await fetch(`${API_URL}:${API_PORT}/${ENDPOINT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(relation),
      });

      if (!response.ok) {
        throw new Error(texts.categoryImageRelationError); // Hata fırlat
      } else {
        return response; // Başarı durumunda response'u döndür
      }
    } catch (error) {
      throw error; // Hata fırlat
    }
  };

  const saveImage = async () => {
    setLoading(true);

    try {
      const imageResponse = await uploadImage();
      if (imageResponse && imageResponse?.imageId) {
        const relationResponse = await handleRelationCategoryAndImage(
          imageResponse?.imageId
        );
        if (relationResponse.ok) {
          myToast({ status: "success", message: texts.categorySaveSuccess });
          navigate("/category/reviewSubCategory/" + subCategoryId);
        } else {
          myToast({
            status: "error",
            message: texts.categoryImageRelationError,
          });
        }
      } else {
        myToast({ status: "error", message: texts.categorySaveError });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.categorySaveError });
    }
    setLoading(false);
  };

  const handleOpen = () => setOpenModal(!openModal);

  return (
    <>
      {" "}
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <CircularProgress size={80} />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl p-4 ">
          <Typography variant="h5">{texts.addSubCategoryImages}</Typography>

          <CardContent className="rounded-none gap-8">
            <div className="w-full flex justify-center">
              <img
                className="w-[300px] rounded-xl object-cover object-center shadow-xl"
                src={
                  imageData ||
                  `https://placehold.co/1000x1000?text=${texts.addImage}`
                }
                alt="categoryImage"
              />
              <input
                type="file"
                id="file-upload"
                accept="image/jpeg, image/png, image/jpg, image/heic" // Sadece resim dosyalarını seçebilmesi için
                ref={fileInputRef} // useRef ile oluşturulan referans burada atanıyor
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              <label
                htmlFor="file-upload"
                className="absolute  font-[Inter] flex items-center justify-center cursor-pointer"
                onClick={triggerFileInput} // Butona tıklandığında triggerFileInput fonksiyonu çağrılıyor
              >
                <Button
                  sx={{
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                    width: "300px",
                  }}
                  variant="contained"
                  size="medium"
                >
                  {texts.addImage}
                </Button>
              </label>
            </div>
          </CardContent>
          <CardContent className="px-0">
            <div className="w-full h-full flex flex-col gap-2">
              <span
                className="text-sm font-[Inter] mt-2 pl-2 italic "
              >
                {texts.category} : {subCategoryName}
              </span>
            </div>
          </CardContent>
          <CardActions className="flex justify-end">
            <Button
              color="error"
              variant="contained"
              onClick={() => navigate(-1)}
              className="mr-1 font-[Inter]"
            >
              <span>{texts.categoryCancel}</span>
            </Button>
            <Button
              onClick={() => saveImage()}
              className="font-[Inter]"
              variant="contained"
              color="success"
            >
              <span>{texts.categorySave}</span>
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default Index;
