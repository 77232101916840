import React from "react";
import { useUserRole } from "./UserRoleProvider"; // Yolunuza göre ayarlayın

const CanViewPage = ({ children, canview }) => {
  const { userRole } = useUserRole();

  if (
    userRole === "superadmin" ||
    canview === "everyone" ||
    userRole === canview
  ) {
    return (
      <div className="w-full font-[Inter] flex flex-col md:flex-row justify-center items-center">
        {children}
      </div>
    );
  } else {
    return null;
  }
};

export default CanViewPage;
