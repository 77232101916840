import React, { useEffect, useState } from "react";
import {
  Card,
  CircularProgress,
  Typography,
  CardContent,
  FormControlLabel,
  Checkbox,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
} from "@mui/material";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useParams } from "react-router-dom";
import { languages } from "../../../constants/languages/languages";
import moment from "moment";

// Helper: Tarihleri ayarla
const getDefaultDates = () => {
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(today.getMonth() - 12);

  return {
    beginDate: oneMonthAgo.toISOString().split("T")[0],
    endDate: today.toISOString().split("T")[0],
  };
};

// API İsteği: Bayi bilgilerini getir
const fetchDealerData = async (dealerId) => {
  const response = await fetch(
    `${API_URL}:${API_PORT}/dealerPostalCode/findByDealerId?dealerId=${encodeURIComponent(
      dealerId
    )}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!response.ok) throw new Error("Bayi bilgileri alınırken hata oluştu");
  const data = await response.json();
  return data?.content[0];
};

// API İsteği: Form gönderimi
const submitForm = async (payload, texts) => {
  const response = await fetch(
    `${API_URL}:${API_PORT}/user/getAllUserWithDealerId`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );
  const responseData = await response.json();
  if (!response.ok) {
    throw new Error(texts.submitError);
  }
  return responseData;
};

// Ana Bileşen
const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dealerData, setDealerData] = useState(null);
  const [selectedPostalCodes, setSelectedPostalCodes] = useState([]);
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("userName");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      const { beginDate, endDate } = getDefaultDates();
      setBeginDate(beginDate);
      setEndDate(endDate);

      setLoading(true);
      try {
        const data = await fetchDealerData(id);
        setDealerData(data);

        const postalCodes = data?.postalCodes || [];
        setSelectedPostalCodes(postalCodes); // İlk yüklemede tüm postal kodlarını seçili yap
        if (postalCodes.length > 0) {
          await handleSubmit(postalCodes || [], beginDate, endDate);
        }
      } catch (error) {
        myToast({ status: "error", message: texts.errorFetchingData });
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [id]);

  const handleSubmit = async (postalCodes, beginDate, endDate) => {
    if (
      !beginDate ||
      !endDate ||
      isNaN(new Date(beginDate)) ||
      isNaN(new Date(endDate))
    ) {
      myToast({ status: "error", message: texts.invalidDate });
      return;
    }

    const payload = {
      dealers: postalCodes.map((item) => ({
        dealerId: id,
        dealerPhone: "string",
        dealerPostalCode: item.postalCode,
        dealerCountry: item.countryCode,
      })),
      beginDate: new Date(beginDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      param: null,
    };

    setSubmitting(true); // Yükleme başlat
    try {
      const data = await submitForm(payload, texts);
      setUsers(data?.content || []);
    } catch (error) {
      myToast({ status: "error", message: error.message });
    } finally {
      setSubmitting(false); // Yükleme bitti
    }
  };

  const handlePostalCodeChange = (postalCodeObj) => {
    setSelectedPostalCodes((prevSelected) =>
      prevSelected.some((item) => item.postalCode === postalCodeObj.postalCode)
        ? prevSelected.filter(
            (item) => item.postalCode !== postalCodeObj.postalCode
          )
        : [...prevSelected, postalCodeObj]
    );
  };

  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (order === "asc") {
      return a[orderBy]?.localeCompare(b[orderBy]) || 0;
    } else {
      return b[orderBy]?.localeCompare(a[orderBy]) || 0;
    }
  });

  if (loading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <CircularProgress size={80} />
      </div>
    );
  }

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: "20px",
        gap: "20px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
    >
      <Typography variant="h5">{texts.viewDealerStatistics}</Typography>
      <CardContent>
        {dealerData ? (
          <>
            <Typography variant="h6">
              {texts.dealerTexts.dealerName}: {dealerData.dealerName}
            </Typography>
            <Typography variant="subtitle2">
              {texts.dealerTexts.pleaseSelectZipCodes}
            </Typography>
            <div className="flex flex-wrap gap-2">
              {dealerData?.postalCodes?.map((postalCodeObj) => (
                <FormControlLabel
                  key={postalCodeObj.postalCodeId}
                  control={
                    <Checkbox
                      checked={selectedPostalCodes.some(
                        (item) => item.postalCode === postalCodeObj.postalCode
                      )}
                      onChange={() => handlePostalCodeChange(postalCodeObj)}
                      color="primary"
                    />
                  }
                  label={`${postalCodeObj.postalCode} (${postalCodeObj.countryCode})`}
                />
              ))}
            </div>
            <Paper
              sx={{
                padding: "20px",
                marginTop: "20px",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              }}
            >
              {/* Yükleniyor animasyonu */}
              {submitting ? (
                <div className="flex items-center justify-center">
                  <CircularProgress size={50} />
                </div>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "userName"}
                            direction={orderBy === "userName" ? order : "asc"}
                            onClick={() => handleSortRequest("userName")}
                          >
                            {texts.userTableTitle.userName}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "userFirmName"}
                            direction={
                              orderBy === "userFirmName" ? order : "asc"
                            }
                            onClick={() => handleSortRequest("userFirmName")}
                          >
                            {texts.userTableTitle.companyName}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "userPhone"}
                            direction={orderBy === "userPhone" ? order : "asc"}
                            onClick={() => handleSortRequest("userPhone")}
                          >
                            {texts.userTableTitle.phone}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "userEmail"}
                            direction={orderBy === "userEmail" ? order : "asc"}
                            onClick={() => handleSortRequest("userEmail")}
                          >
                            {texts.userTableTitle.email}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "userTaxNumber"}
                            direction={
                              orderBy === "userTaxNumber" ? order : "asc"
                            }
                            onClick={() => handleSortRequest("userTaxNumber")}
                          >
                            {texts.userTableTitle.userTaxNumber}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "userCountry"}
                            direction={
                              orderBy === "userCountry" ? order : "asc"
                            }
                            onClick={() => handleSortRequest("userCountry")}
                          >
                            {texts.userTableTitle.userZipCode}
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === "createdAt"}
                            direction={orderBy === "createdAt" ? order : "asc"}
                            onClick={() => handleSortRequest("createdAt")}
                          >
                            {texts.userModalTable.registerDate}
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedUsers.map((user) => (
                        <TableRow key={user.userId}>
                          <TableCell>
                            {user.userName + " " + user.userSurname} <br />
                            {user.userUsername} <br />
                          </TableCell>
                          <TableCell>{user.userFirmName}</TableCell>
                          <TableCell>{user.userTaxNumber}</TableCell>
                          <TableCell>{user.userEmail}</TableCell>
                          <TableCell>{user.userPhone}</TableCell>
                          <TableCell>
                            {`${user.userCountry?.toUpperCase() || ""} - ${
                              user.userPostalCode || ""
                            }`}
                          </TableCell>
                          <TableCell>
                            {moment(user.createdAt).format("DD.MM.YYYY")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </>
        ) : (
          <Typography>{texts.noDealerData}</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default Index;
