import React, { useState, useRef } from "react";
import {
  Card,
  CardContent,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { languages } from "../../../constants/languages/languages";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [packageItem, setPackageItem] = useState({
    packageNameTr: "",
    packageNameDe: "",
    packageNameFr: "",
    packageNameIt: "",
    packageNameNl: "",
    packageNameEn: "",
    packageDescription: "",
    packageMontly: "",
    package3Montly: "",
    package6Montly: "",
    package12Montly: "",
    package24Montly: "",
    packageSerial: "",
    packageType: "0",
    packageRegion: currentLanguage,
  });

  const [images, setImages] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const fileInputRef = useRef(null);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const savePackage = async () => {
    setLoading(true);
    try {
      const uploadImagesResponse = await uploadImages();
      if (uploadImagesResponse.length > 0) {
        const payload = {
          ...packageItem,
          packageBgIds: uploadImagesResponse.join(","),
        };
        const response = await fetch(
          `${API_URL}:${API_PORT}/package/savePackage`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (response.status === 200) {
          showSnackbar("success", texts.packageSaveSuccess);
          navigate("/packages");
        } else {
          showSnackbar("error", texts.packageSaveError);
        }
      }
    } catch (error) {
      console.log(error);
      showSnackbar("error", texts.packageSaveError);
    }
    setLoading(false);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const newImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        showSnackbar("error", texts.fileExtensionError);
        continue;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imageData = reader.result;
        newImages.push({ file, imageData });
        if (newImages.length === files.length) {
          setImages((prevImages) => [...prevImages, ...newImages]);
        }
      };
    }
  };

  const handleInputChange = (e, key) => {
    let value = e.target.value.replace(",", ".");
    if (!isNaN(value)) {
      setPackageItem({
        ...packageItem,
        [key]: value,
      });
    }
  };

  const uploadImages = async () => {
    try {
      const newImageIds = [];

      for (const image of images) {
        const formData = new FormData();
        formData.append("file", image.file);
        const imageType = "backgroundimages";
        const response = await fetch(
          `${API_URL}:${API_PORT}/image/uploadImage?imageType=${imageType}`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          newImageIds.push(data.imageId);
          showSnackbar("success", texts.imageUploadSuccess);
        } else {
          showSnackbar("error", texts.imageUploadError);
        }
      }

      return newImageIds;
    } catch (error) {
      showSnackbar("error", texts.imageUploadError);
      console.error(error);
      return [];
    }
  };

  const handleSave = async () => {
    await savePackage();
  };

  const showSnackbar = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <CircularProgress size={60} />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl p-4">
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <h2>{texts.addDealer}</h2>
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameDe}
              value={packageItem.packageNameDe}
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageNameDe: e.target.value,
                  packageSerial: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameEn}
              value={packageItem.packageNameEn}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameEn: e.target.value,
                  packageSerial: e.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameTr}
              value={packageItem.packageNameTr}
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageNameTr: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameFr}
              value={packageItem.packageNameFr}
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageNameFr: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameIt}
              value={packageItem.packageNameIt}
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageNameIt: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameNl}
              value={packageItem.packageNameNl}
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageNameNl: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageDescription}
              value={packageItem.packageDescription}
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageDescription: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageMonthly}
              value={packageItem.packageMontly}
              onChange={(e) => handleInputChange(e, "packageMontly")}
              fullWidth
            />

            <select
              className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageType: e.target.value,
                })
              }
            >
              <option value="0">{texts.packageType0}</option>
              <option value="1">{texts.packageType1}</option>
              <option value="2">{texts.packageType2}</option>
            </select>
            <select
              className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageRegion: e.target.value,
                })
              }
            >
              {Object.keys(languages).map((langKey) => (
                <option key={langKey} value={langKey}>
                  {langKey}
                </option>
              ))}
            </select>
            <select
              value={packageItem.packageSelectSize}
              className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageSelectSize: e.target.value,
                })
              }
            >
              {[...Array(51)].map((_, index) => (
                <option key={index} value={index}>
                  {index}{" "}
                  {" " + texts.packagesTableTitle.packageSelectImageSize}
                </option>
              ))}
            </select>
            <div className="flex justify-between mt-4">
              <Button variant="contained" onClick={triggerFileInput}>
                {texts.addImage}
              </Button>
            </div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileUpload}
              multiple
            />
            <div className="flex flex-wrap w-full gap-4 justify-start">
              {Array.isArray(images) &&
                images.map((image, index) => (
                  <div
                    key={index}
                    className="relative group flex justify-center items-center"
                  >
                    <img
                      className="h-[105px] w-[75px] object-cover rounded-md"
                      src={image.imageData}
                      alt={`Image_${index + 1}`}
                    />
                    <button
                      onClick={() => {
                        const newImages = images.filter(
                          (img, i) => i !== index
                        );
                        setImages(newImages);
                      }}
                      className="absolute top-2 right-2 z-10 p-1 bg-white rounded-full shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                    >
                      <DeleteForeverIcon className="h-5 w-5 text-red-300 hover:text-red-700" />
                    </button>
                  </div>
                ))}
            </div>
            <Button variant="contained" color="primary" onClick={handleSave}>
              {texts.savePackage}
            </Button>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
              severity={snackbarSeverity}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Index;
