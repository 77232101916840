import { languages } from "../../../constants/languages/languages";
import React, { useRef, useState, useEffect } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import DeafultUserImage from "../../../assets/images/defaultUserImage.png";
import {
  CardContent,
  Card,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { countryOptions } from "../../../constants/countries";

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
};

const getPackageName = (pkg, currentLanguage) => {
  const packageName = {
    tr: pkg.packageNameTr,
    de: pkg.packageNameDe,
    fr: pkg.packageNameFr,
    it: pkg.packageNameIt,
    nl: pkg.packageNameNl,
    en: pkg.packageNameEn,
  };

  return packageName[currentLanguage] || packageName.en;
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [userPackage, setUserPackage] = useState({ packageId: "" });
  const [userBankAccount, setUserBankAccount] = useState({
    accountNumber: "",
    iban: "",
    bankName: "",
    bankAccountUserName: "",
    bankAccountUserSurname: "",
  });
  const [isSelectedPackageMonth, setIsSelectedPackageMonth] = useState(1);
  const [user, setUser] = useState({
    userName: "",
    userSurname: "",
    userUsername: "",
    userPassword: "",
    userRole: "user",
    userTrial: false,
    userEmail: "",
    userPhone: "",
    userFirmName: "",
    userTaxNumber: "",
    userSelectedBg: "",
    userCountry: "de",
    userPostalCode: "",
  });
  const [changeImage, setChangeImage] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [packages, setPackages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [userSaving, setUserSaving] = useState(false);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const getAllPackage = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/package/getAllPackageWithBg?page=0&size=9999999`
      );
      const data = await response.json();
      setPackages(data?.content || []);
      setUserPackage(data?.content[0]);
    } catch (error) {
      myToast({ status: "error", message: error.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPackage();
  }, []);

  const handleImageSelection = (imageId) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(imageId)) {
        return prevSelectedImages.filter((id) => id !== imageId);
      } else {
        return [...prevSelectedImages, imageId];
      }
    });
  };

  const handleSelectPackageChange = (selectedOption) => {
    const selectedPackage = packages.find(
      (pkg) => pkg.packageId === selectedOption.value
    );
    setUserPackage(selectedPackage);
  };

  const packageOptions = packages.map((pkg) => ({
    value: pkg.packageId,
    label: getPackageName(pkg, currentLanguage),
  }));

  const selectedPackageOption = userPackage
    ? {
        value: userPackage.packageId,
        label: getPackageName(userPackage, currentLanguage),
      }
    : null;



  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      myToast({ status: "error", message: texts.fileExtensionError });
      return;
    }

    setNewImage(file);
    setChangeImage(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageData(reader.result);
    };
  };

  const uploadImage = async (userImage) => {
    try {
      const formData = new FormData();
      formData.append("file", userImage);
      const imageType = "userimages";
      const response = await fetch(
        `${API_URL}:${API_PORT}/image/uploadImage?imageType=${imageType}`,
        {
          method: "POST",
          body: formData,
        }
      );
      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  const saveUser = async (sendedImage) => {
    try {
      const userDto = {
        userDTO: {
          userName: user.userName,
          userSurname: user.userSurname,
          userUsername: user.userUsername,
          userPassword: user.userPassword,
          userRole: user.userRole,
          userTrial: user.userTrial,
          userEmail: user.userEmail,
          userPhone: user.userPhone,
          userFirmName: user.userFirmName,
          userTaxNumber: user.userTaxNumber,
          userCountry: user.userCountry,
          userPostalCode: user.userPostalCode,
          userSelectedBg: selectedImages.join(","),
          image: {
            imageId: sendedImage?.imageId,
          },
        },
        bankAccountDTO: userBankAccount,
        apackage: {
          packageId: userPackage.packageId,
        },
        isSelectedPackageMonth: isSelectedPackageMonth,
      };

      const response = await fetch(
        `${API_URL}:${API_PORT}/user/CollectiveSaveUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userDto),
        }
      );

      return await response.json();
    } catch (error) {
      myToast({ status: "error", message: texts.saveStatus.userSaveError });
    }
  };

  const saveUserImageAndUserRelation = async (imageId, userId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/ImageUserRelation/saveImageUserRelation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            image: {
              imageId: imageId,
            },
            user: { userId: userId },
            imageType: "userimages",
            status: true,
          }),
        }
      );
      return await response.json();
    } catch (error) {
      myToast({ status: "error", message: texts.saveStatus.relationSaveError });
    }
  };

  const saveUserAndImage = async () => {
    setUserSaving(true);
    try {
      const defaultUserImage = await fetch(DeafultUserImage);
      const defaultUserImageBlob = await defaultUserImage.blob();
      const defaultUserImageFile = new File(
        [defaultUserImageBlob],
        "defaultUserImage.png"
      );
      const userImage = newImage || defaultUserImageFile;
      let imageResponse = await uploadImage(userImage);
      if (!imageResponse?.imageId) {
        myToast({
          status: "error",
          message: texts.saveStatus.imageSaveError,
        });
        return;
      }

      const userResponse = await saveUser(imageResponse);

      if (userResponse?.userDTO?.userId) {
        if (imageResponse?.imageId) {
          const relationResponse = await saveUserImageAndUserRelation(
            imageResponse.imageId,
            userResponse?.userDTO?.userId
          );
          if (!relationResponse) {
            myToast({
              status: "error",
              message: texts.saveStatus.relationSaveError,
            });
            return;
          }
        }
        myToast({
          status: "success",
          message: texts.saveStatus.userSaveSuccess,
        });
        navigate("/users");
      } else {
        myToast({
          status: "error",
          message: texts.saveStatus.userSaveError,
        });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.userSaveError });
    }
    setUserSaving(false);
  };

  const handleSave = async () => {
    await saveUserAndImage();
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <CircularProgress size={80} />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl p-4">
          <h2>{texts.userTableTitle.addUser}</h2>
          <div className="rounded-none gap-8">
            <div className="relative h-72 w-full flex justify-center">
              <img
                className="h-[300px] w-[300px] rounded-xl object-cover object-center shadow-xl"
                src={imageData ? imageData : "https://placehold.co/1000x1000"}
                alt="categoryImage"
              />
              <input
                type="file"
                id="file-upload"
                accept="image/jpeg, image/png, image/jpg, image/heic"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
              <label
                htmlFor="file-upload"
                className="absolute font-[Inter] flex items-center justify-center cursor-pointer"
                onClick={triggerFileInput}
              >
                <Button
                  sx={{
                    width: "300px",
                    borderRadius: "0px",
                    opacity: 0.75,
                  }}
                  variant="contained"
                  color="primary"
                >
                  {texts.addImage}
                </Button>
              </label>
            </div>
          </div>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={user.userTrial || false}
                  onChange={(e) =>
                    setUser({ ...user, userTrial: e.target.checked })
                  }
                />
              }
              label={texts.userTableTitle.freeTrial}
            />

            <TextField
              size="small"
              label={texts.userTableTitle.name}
              onChange={(e) => setUser({ ...user, userName: e.target.value })}
              value={user.userName || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.surname}
              onChange={(e) =>
                setUser({ ...user, userSurname: e.target.value })
              }
              value={user.userSurname || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userName}
              onChange={(e) =>
                setUser({ ...user, userUsername: e.target.value })
              }
              value={user.userUsername || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userTaxNumber}
              onChange={(e) =>
                setUser({ ...user, userTaxNumber: e.target.value })
              }
              value={user.userTaxNumber || ""}
              fullWidth
              type="number"
            />
            <TextField
              size="small"
              label={texts.userTableTitle.phone}
              onChange={(e) => setUser({ ...user, userPhone: e.target.value })}
              value={user.userPhone || ""}
              fullWidth
              type="number"
            />
            <TextField
              size="small"
              label={texts.userTableTitle.email}
              onChange={(e) => setUser({ ...user, userEmail: e.target.value })}
              value={user.userEmail || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userPassword}
              onChange={(e) =>
                setUser({ ...user, userPassword: e.target.value })
              }
              value={user.userPassword || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.companyName}
              onChange={(e) =>
                setUser({ ...user, userFirmName: e.target.value })
              }
              value={user.userFirmName || ""}
              fullWidth
              type="email"
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userIBAN}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  iban: e.target.value,
                })
              }
              value={userBankAccount.iban || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userBankAccountOwnerName}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  bankAccountUserName: e.target.value,
                })
              }
              value={userBankAccount.bankAccountUserName || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userBankAccountOwnerSurname}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  bankAccountUserSurname: e.target.value,
                })
              }
              value={userBankAccount.bankAccountUserSurname || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userBankName}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  bankName: e.target.value,
                })
              }
              value={userBankAccount.bankName || ""}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userAccountNumber}
              onChange={(e) =>
                setUserBankAccount({
                  ...userBankAccount,
                  accountNumber: e.target.value,
                })
              }
              value={userBankAccount.accountNumber || ""}
              fullWidth
              type="number"
            />
            <TextField
              size="small"
              label={texts.userTableTitle.userZipCode}
              onChange={(e) =>
                setUser({ ...user, userPostalCode: e.target.value })
              }
              value={user.userPostalCode || ""}
              fullWidth
              inputProps={{ maxLength: 5 }}
              type="number"
            />

            <Select
              options={countryOptions}
              styles={customStyles}
              value={countryOptions.find(
                (option) => option.value === user.userCountry
              )}
              onChange={(e) => setUser({ ...user, userCountry: e.value })} // Seçilen ülkenin id'sini kaydet
              isSearchable
              className="font-[Inter] w-full"
            />

            <Select
              className="w-full h-10 rounded-lg"
              value={selectedPackageOption}
              onChange={handleSelectPackageChange}
              options={packageOptions}
              placeholder="Select a package..."
            />

            {userPackage && userPackage.packageImages && (
              <div className="image-selection flex flex-wrap gap-4 mt-4">
                {userPackage.packageImages.map((item) => (
                  <div
                    key={item.imageId}
                    onClick={() => handleImageSelection(item.imageId)}
                    style={{
                      position: "relative",
                      cursor: "pointer",
                      border: "2px solid",
                      borderColor: selectedImages.includes(item.imageId)
                        ? "blue"
                        : "gray",
                      borderRadius: "8px",
                      overflow: "hidden",
                      width: "100px",
                      display: "inline-block",
                    }}
                  >
                    <img
                      src={`${API_URL}:${API_PORT}/${item.imageName}`}
                      alt={item.imageName}
                      style={{
                        display: "block",
                        width: "100px",
                        transition: "opacity 0.3s ease",
                        opacity: selectedImages.includes(item.imageId)
                          ? 0.6
                          : 1,
                        objectFit: "cover",
                      }}
                    />
                    {selectedImages.includes(item.imageId) && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "white",
                          fontSize: "24px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          padding: "5px 10px",
                          borderRadius: "5px",
                        }}
                      >
                        ✓
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={userSaving}
            >
              {user.userId
                ? texts.userTableTitle.updateUser
                : texts.userTableTitle.addUser}
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Index;
