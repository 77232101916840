import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate, useParams } from "react-router-dom";
import { languages } from "../../../constants/languages/languages";

const Index = () => {
  const { id } = useParams();

  useEffect(() => {
    getAllCategories();
    if (id) {
      getSubCategoriesByCategoryId(id);
    }
  }, []);

  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage].addCategoryItems;
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [subCategory, setSubCategory] = useState({
    subCategoryId: "",
    subCategoryNameTr: "",
    subCategoryNameEn: "",
    subCategoryNameDe: "",
    subCategoryNameFr: "",
    subCategoryNameIt: "",
    subCategoryNameNl: "",
    subCategoryDescription: "",
    subCategorySerial: "",
    language: currentLanguage,
    subCategoryStatus: true,
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getAllCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/category/getAllCategory?page=${0}&size=${1000000}`
      );
      const data = await response.json();
      setSelectedMainCategory(data?.content[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getSubCategoriesByCategoryId = async (categoryId) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/subCategory/findBySubCategoryId?subCategoryId=${categoryId}`
      );
      const data = await response.json();
      setSubCategory(data);
    } catch (error) {
      console.log(error);
    }
  };

  const saveSubCategory = async () => {
    try {
      const payload = {
        ...subCategory,
        subCategorySerial: subCategory?.subCategoryNameEn,
        category: {
          categoryId: selectedMainCategory?.categoryId,
        },
      };
      const saveResponse = await fetch(
        `${API_URL}:${API_PORT}/subCategory/saveSubCategory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      return saveResponse;
    } catch (error) {
      throw error;
    }
  };

  const saveCategory = async () => {
    try {
      const saveResponse = await saveSubCategory();
      if (saveResponse.status === 200) {
        myToast({ status: "success", message: texts.categorySaveSuccess });
        navigate("/category");
      } else {
        myToast({ status: "error", message: texts.categorySaveError });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.categorySaveError });
    }
  };

  return (
    <Card className="w-full h-full shadow-2xl p-4">
      <h3>{texts.addCategory}</h3>

      <CardContent>
        {loading ? (
          <div className="w-full min-w-max flex items-center justify-center">
            <CircularProgress className="h-40 w-40" />
          </div>
        ) : (
          <div className="w-full h-full flex flex-col gap-2">
            <TextField
              size="small"
              label={texts.categoryNameDe}
              value={subCategory?.subCategoryNameDe}
              onChange={(e) =>
                setSubCategory({
                  ...subCategory,
                  subCategoryNameDe: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.categoryNameEn}
              value={subCategory?.subCategoryNameEn}
              onChange={(e) =>
                setSubCategory({
                  ...subCategory,
                  subCategoryNameEn: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.categoryNameTr}
              value={subCategory?.subCategoryNameTr}
              onChange={(e) =>
                setSubCategory({
                  ...subCategory,
                  subCategoryNameTr: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.categoryNameFr}
              value={subCategory?.subCategoryNameFr}
              onChange={(e) =>
                setSubCategory({
                  ...subCategory,
                  subCategoryNameFr: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.categoryNameIt}
              value={subCategory?.subCategoryNameIt}
              onChange={(e) =>
                setSubCategory({
                  ...subCategory,
                  subCategoryNameIt: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.categoryNameNl}
              value={subCategory?.subCategoryNameNl}
              onChange={(e) =>
                setSubCategory({
                  ...subCategory,
                  subCategoryNameNl: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              size="small"
              label={texts.categoryDescription}
              value={subCategory?.subCategoryDescription}
              onChange={(e) =>
                setSubCategory({
                  ...subCategory,
                  subCategoryDescription: e.target.value,
                })
              }
              fullWidth
            />
          </div>
        )}
      </CardContent>

      <CardActions className="flex justify-end">
        <Button
          color="error"
          variant="contained"
          onClick={() => navigate(-1)}
          className="mr-1 font-[Inter]"
        >
          {texts.categoryCancel}
        </Button>
        <Button
          className="font-[Inter]"
          variant="contained"
          color="success"
          onClick={() => saveCategory()}
        >
          {texts.categorySave}
        </Button>
      </CardActions>
    </Card>
  );
};

export default Index;
